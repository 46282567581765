//
// Links (anchors)
// --------------------------------------------------

// Variables
// ---------------------------------------
@link-text-decoration: 			none;
@link-hover-text-decoration: 	underline;
@link-transition: 				all 150ms ease-out;

// Link Colors
// --------------------------
@link-color:					@site-color-primary;
@link-highlight:				lighten(@link-color, 10%);
@link-shade:					darken(@link-color, 20%);
//	|	|
@link-secondary-color: 			@perch-color-secondary;
@link-secondary-highlight:		lighten(@link-secondary-color, 10%);
@link-secondary-shade:			darken(@link-secondary-color, 40%);
//	|	|
@link-tertiary-color: 			@site-color-tertiary;
@link-tertiary-highlight:		lighten(@link-tertiary-color, 10%);
@link-tertiary-shade:			darken(@link-tertiary-color, 40%);
//	|	|
@link-light-color: 				@perch-color-secondary;
@link-light-highlight:			lighten(@link-light-color, 10%);
@link-light-shade:				darken(@link-light-color, 40%);
//	|	|
@link-grayed-color: 			@perch-color-gray;
@link-grayed-highlight:			lighten(@link-grayed-color, 10%);
@link-grayed-shade:				darken(@link-grayed-color, 40%);
// ---------------------------------------


// Styles
// ---------------------------------------
a {
	color: @link-color;
	text-decoration: @link-text-decoration;

	.transition(@link-transition);

	// &:link {}
	// &:visited {
	// 	color: desaturate(@link-color, 100%);
	// }
	&:focus,
	&:hover {
		color: @link-highlight;
		text-decoration: @link-hover-text-decoration;
	}
	&:active {
		color: @link-shade;
	}
	// &:hover:focus {}
	// &:hover:active {}
}


// Link color extensions
// ---------------------------------------
.link-secondary {
	color: @link-secondary-color;
	&:focus,
	&:hover {
		color: @link-secondary-highlight;
	}
	&:active {
		color: @link-secondary-shade;
	}
}
.link-tertiary {
	color: @link-tertiary-color;
	&:focus,
	&:hover {
		color: @link-tertiary-highlight;
	}
	&:active {
		color: @link-tertiary-shade;
	}
}
.link-light {
	color: @link-light-color;
	&:focus,
	&:hover {
		color: @link-light-highlight;
	}
	&:active {
		color: @link-light-shade;
	}
}
.link-grayed {
	color: @link-grayed-color;
	&:focus,
	&:hover {
		color: @link-grayed-highlight;
	}
	&:active {
		color: @link-grayed-shade;
	}
}

// Link Enhancers
// ---------------------------------------
// .truncated-indicator {
// 	color: lighten(@global-text-color, 10%);
// }

// .link-indicator {
// 	position: relative;
// 	display: inline-block;
// 	vertical-align: top;
// 	font-size: 1.35em;
// 	line-height: 1;

// 	.transition(all .12s ease);


// 	a:hover & {
// 		.transform(translateX(.125em));
// 	}
// }

// // The whole block is a link. Defines hover effects
// .block-link {
// 	display: block;
// 	  @the-shadow: 0 0 0 3px fade(#fff, 0%), 0 0 0 4px fade(@link-color, 0%);
// 	.box-shadow (@the-shadow);

// 	// States
// 	&:focus,
// 	&:hover {
// 		background-color: fade(#fff, 50%);
// 		  @the-shadow: 0 0 0 3px fade(#fff, 95%), 0 0 0 4px fade(@link-color, 35%);
// 		.box-shadow (@the-shadow);
// 	}
// }
