//
// Site unique utilities
// --------------------------------------------------

// Example
// .text-color-primary {
// 	color: @site-color-primary !important;
// }

.link-secondary {
    color: @site-color-secondary;
    &:focus,
    &:hover {
        color: @site-color-secondary-highlight;
    }
    &:active {
        color: @site-color-secondary-shade;
    }
}

// Fix a syntax error in Perch's utility file...
// ---------------------------------------
.u-font-size-inherit { font-size: inherit !important; }
