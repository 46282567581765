//
// Alerts
// --------------------------------------------------


// Variables
// ---------------------------------------

// Base alert styles _________
@alert-border-color: 				lighten(@alert-color, 30%);
@alert-border-width: 				1px;
@alert-box-shadow:					0 0 2px 1px fade(#000, 12%);

// Heading
@alert-heading-margin-bottom: 		@common-gutter;
@alert-heading-font-weight: 		bold;
@alert-heading-text-color: 			inherit;

// Default alert colors
@alert-color: 						#737373;
@alert-warning-color: 				#DFB030;
@alert-error-color: 				#B94A48;
@alert-success-color: 				#55AF4C;
@alert-info-color: 					#65ADBD;

@alert-bg-color: 					lighten(@alert-color, 50%);
@alert-text-color: 					@alert-color;

// Alert color extensions
// --------------------------

// Warning alert colors
@alert-warning-border-color: 		lighten(@alert-warning-color, 20%);
@alert-warning-bg-color: 			lighten(@alert-warning-color, 40%);
@alert-warning-text-color: 			@alert-warning-color;

// Error alert colors
@alert-error-border-color: 			lighten(@alert-error-color, 20%);
@alert-error-bg-color: 				lighten(@feedback-error-color, 40%);
@alert-error-text-color: 			@alert-error-color;

// Success alert colors
@alert-success-border-color: 		lighten(@alert-success-color, 10%);
@alert-success-bg-color: 			lighten(@feedback-success-color, 44%);
@alert-success-text-color: 			@alert-success-color;

// Info alert colors
@alert-info-border-color: 			lighten(@alert-info-color, 10%);
@alert-info-bg-color: 				lighten(@feedback-info-color, 40%);
@alert-info-text-color: 			@alert-info-color;
// ---------------------------------------


// Styles
// ---------------------------------------
.alert {
	.island;
	position: relative;
	border: @alert-border-width solid @alert-border-color;
	background-color: @alert-bg-color;
	color: @alert-text-color;

	.box-shadow (@alert-box-shadow);

	// Element Children
	// --------------------------
	> .heading {
		margin-bottom: @alert-heading-margin-bottom;

		font-weight: @alert-heading-font-weight;
		color: @alert-heading-text-color;
	}
	> .close {
		position: absolute;
		top:   0;
		right: 0;

		width: 		 1.4em;
		line-height: 1em;
		height: 	 1.4em;
		border: 0;

		background-color: transparent;
		text-align: center;
		font-size: 1.4em;
		text-decoration: none;
		color: fade(#000, 50%);

		&:focus,
		&:hover {
			background-color: fade(#000, 50%);
			color: #fff;
		}
	}
}

// Layout extensions
// ---------------------------------------

// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated in version 0.8.0
// Deprecated because:
//  - Alert borders & box-shadows can already be turned off just with variables, and it's unlikely for a site/app to need two different styles of alerts. If that's the case, the developer can introduce their own class.
.alert--borderless {
	border: 0;
	.box-shadow (none);
}
// end deprecated code ☠☠☠☠


// Functional / color extensions
// ---------------------------------------
.alert--warning {
	border-color: @alert-warning-border-color;
	background-color: @alert-warning-bg-color;
	color: @alert-warning-text-color;
	> .close:focus,
	> .close:hover { background-color: @alert-warning-color; }
}
.alert--error {
	border-color: @alert-error-border-color;
	background-color: @alert-error-bg-color;
	color: @alert-error-text-color;
	> .close:focus,
	> .close:hover { background-color: @alert-error-color; }
}
.alert--success {
	border-color: @alert-success-border-color;
	background-color: @alert-success-bg-color;
	color: @alert-success-text-color;
	> .close:focus,
	> .close:hover { background-color: @alert-success-color; }
}
.alert--info {
	border-color: @alert-info-border-color;
	background-color: @alert-info-bg-color;
	color: @alert-info-text-color;
	> .close:focus,
	> .close:hover { background-color: @alert-info-color; }
}
