//
// Globals
// --------------------------------------------------
// html, body, global wrapper, interaction styles
// --------------------------------------------------


// Variables
// ---------------------------------------
// -- most of the global variables are set
//    in _perch-global-variables.less
// ---------------------------------------


// Wrapper Styles
// ---------------------------------------
html {
	min-height: 100%;

	font-family: @font-family-base;
	font-size: @font-size-base-mobile;
	font-weight: normal;
	color: @global-text-color;

	background-color: @html-bg-color;

	// ☠☠☠☠ DEPRECATED ☠☠☠☠
	// overflow: auto;

	// Note: if this is set, then triggering overflow: hidden on the <body> will no longer lock the <body> from scrolling. This reared its ugly head when working with a modal element.

	// Not sure what this overflow setting was being used for other than some scrollbar preferences maybe. But not sure if removing this could be somehow breaking something... KB 2017-02-16
	// end deprecated code ☠☠☠☠

	// Scale up global font size at larger widths
	@media (min-width: @viewport-width-font-size-tablet) {
		font-size: @font-size-base-tablet;
	}
	@media (min-width: @viewport-width-font-size-desktop) {
		font-size: @font-size-base;
	}
}


body {
	position: relative;

	min-height: 100vh; // Force the body to be at least as high as the viewport

	background-color: @body-bg-color;

	line-height: @line-height-base;
}


.global-wrapper {
	.clear_float ();
	position: relative;
	height: 100%;
}



// Interaction Styles
// ---------------------------------------

// Selection styles
// --------------------------
::-moz-selection { color: @selection-text-color; background: @selection-bg-color; text-shadow: none; }
::selection		 { color: @selection-text-color; background: @selection-bg-color; text-shadow: none; }

// Tap Highlight Color
// --------------------------
body {-webkit-tap-highlight-color: @tap-highlight-bg-color; }
