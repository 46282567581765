//
// Lists
// --------------------------------------------------


// Variables
// ---------------------------------------
@lists-indentation: 						2em;

@child-list-margin-top: 					0;
@child-list-margin-bottom: 					0;

@child-ordered-list-style-type: 			lower-alpha;
@grandchild-ordered-list-style-type: 		lower-roman;
@great-grandchild-ordered-list-style-type: 	decimal;
// ---------------------------------------


// Styles — Standard List Styles
// ---------------------------------------
menu, ol, ul {
	padding: 0 0 0 @lists-indentation;
}

li > ul,
li > ol {
	margin-top: @child-list-margin-top;
	margin-bottom: @child-list-margin-bottom;
}


// Ordered list notation changes
// ---------------------------------------
ol ol {
	list-style-type: @child-ordered-list-style-type;

	ol {
		list-style-type: @grandchild-ordered-list-style-type;

		ol {
			list-style-type: @great-grandchild-ordered-list-style-type;
		}
	}
}
