//
// Variables
// --------------------------------------------------

// Directories
// ---------------------------------------
// Imagine this path in the final rendered CSS. It tells the browser where it can find Perch
@perch-dir: 				"../third-party/perch";

// File path for icomoon font folder
@icomoon-font-path: 		"@{perch-dir}/icons/fonts";


// Layout
// ---------------------------------------
@site-max-width: 			1200px;
@site-min-width: 			@iphone-original-width;

@common-gutter: 			20px;
@common-lesser-gutter: 		10px;
@common-vertical-gutter: 	1rem; // how to calculate to a (i.e. 20) pixel size: unit((20 / @font-size-base), rem);


// Mobile
// --------------------------
@mobile-column-gutter: 		(@grid-gutter-width / 2); //(@common-gutter / 4);
@mobile-container-gutter: 	(@grid-gutter-width / 2); //(@common-gutter / 2);

@mobile-common-gutter: 		10px;

@mobile-nav-menu-gutter: 	20px;



// Grid breakpoints
// --------------------------

// Extra small size / phone up
@screen-xs-min: 			@site-min-width; 		// 320px
// Small size / phablet up
@screen-sm-min: 			480px; // = 12 * 40		// old: 414px  = @iphone-6plus-width =
// Medium size / tablet up
@screen-md-min:				660px; // = 12 * 55		// old: 667px  = @iphone-6-height
// Large size / desktop up
@screen-lg-min: 			840px; // = 12 * 70		// old: 1024px = @ipad-height;
// Extra large size / special width considerations up
@screen-xl-min: 			1020px; // = 12 * 100

// Grid breakpoint ranges' max values
@screen-xs-max: 			(@screen-sm-min - 1);
@screen-sm-max: 			(@screen-md-min - 1);
@screen-md-max: 			(@screen-lg-min - 1);
@screen-lg-max: 			(@screen-xl-min - 1);
@screen-xl-max: 			(@screen-xxl-min - 1);

// Grid Helper Widths _________
// These do not have associated grid classes, however they are meant to be used with media queries to provide more context & information
@screen-xxs-min: 			@site-min-width;
@screen-xxs-max: 			@site-min-width;
@screen-xxl-min: 			1440px;

// Mobile vs. Desktop breakpoint
@desktop-styles-min: 		830px;
@mobile-styles-max: 		(@desktop-styles-min - 1);


// Grid system
// --------------------------

// Number of columns in the grid system
@grid-columns:				12;
// Gutter padding, to be split between the left and right of all columns
@grid-gutter-width:			(@common-gutter * 2);

// The gutter on each side for all columns under a .row--thinner class (c.f. grid.less)
@grid-gutter-width--thinner: (@grid-gutter-width / 2);


// Container sizes
// --------------------------

// Small
@container-sm:				((720px + @grid-gutter-width));
// Medium
@container-md:				((940px + @grid-gutter-width));
// Large
@container-lg:				(@site-max-width);
// Extra-large
@container-xl: 				@screen-xl-max;



// Type + Fonts
// ---------------------------------------


// Families
// --------------------------
@font-family-serif: 		"Georgia", Times New Roman, Times, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@font-family-sans: 			Freesans, Helmet, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@font-family-sans-serif:	@font-family-sans;
@font-family-helvetica:		Freesans, Helmet, clean, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@font-family-clean: 		@font-family-helvetica;
@font-family-system: 		system-ui, BlinkMacSystemFont, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@font-family-code: 			Monaco, Menlo, Consolas, "Courier New", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@font-family-iconfont: 		'perch-icons';

@font-family-base: 			@font-family-sans;
@font-family-alternate: 	@font-family-serif;


// Sizes
// --------------------------
@font-size-base: 			18px;
@font-weight-base: 			normal;
@line-height-base: 			1.5;


// Font size scaling _________
// To turn font size scaling off, set/override the viewport-width variables to 101vw
@font-size-base-mobile: 	@font-size-base * .8333333; // 15px if base == 18px
@font-size-base-tablet: 	@font-size-base * .9166667; // 16.5px if base == 18px

@viewport-width-font-size-tablet: 		@screen-md-min;
@viewport-width-font-size-desktop: 		@screen-lg-min;



// Misc elements
// ---------------------------------------
@common-border-radius: 		7px;



// Colors
// ---------------------------------------


// Generics & Meta
// --------------------------
@perch-color-primary: 		#204F3C;
@perch-color-secondary: 	#9f675c; // #9F4533
@perch-color-tertiary: 		#f5b62e;
@perch-color-gray: 			#b6bfcd;
@perch-color-gray-lighter: 	#ededed;


// Brand Colors
// --------------------------
@brand-color-primary: 		@perch-color-primary;
@brand-color-secondary: 	@perch-color-secondary;
@brand-color-tertiary: 		@perch-color-tertiary;


// Functional Global Colors
// --------------------------
@site-color-primary:		@brand-color-primary;
@site-color-secondary:		@brand-color-secondary;
@site-color-tertiary:		@brand-color-tertiary;
@global-text-color: 		#555;
//	|	|
// ⏩⏩⏩ MOVED ⏩⏩⏩
// Moved to:
//  - _elements.less
// @table-border-color: 		#999;
// end moved code ⏩⏩⏩
//	|	|
@line-divider-color: 		#b5b5b5;
//	|	|
@focus-highlight-color: 	@site-color-primary;
//	|	|
@ui-highlight-bg-color: 	fade(#FFF, 15%);
@ui-shade-bg-color: 		fade(#000, 15%);
@ui-overlay-shade-bg-color:	fade(#333333, 75%);
//	|	|
// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
//  - variable name not clear
// Actions taken:
//  - replacing with @feedback-highlighter-color
@ui-highlight-color: 		@feedback-highlighter-color; // see the @feedback- section below
// end deprecated code ☠☠☠☠


// Selection Colors _________
@selection-bg-color: 		#B4D5FF;
@selection-text-color: 		#000;
@tap-highlight-bg-color: 	transparent; // Removing this. 2017-10-20 KB. Formerly #5CA8FF;


// Layout Global Colors
// --------------------------
@html-bg-color: 			#FFF;
@body-bg-color: 			@html-bg-color;

// »»» header & nav color styles moved to _layout-header.less and _layout-desktop.less
//	|	|
// 💀💀💀💀 REMOVED 💀💀💀💀
// Removed because:
//  - Too many drawbacks to this method
// @main-content-bg-color: 	#FFF; // also the color that "flashes" when transitioning between pages
// end removed code 💀💀💀💀
// ⏩⏩⏩ MOVED ⏩⏩⏩
// Moved to:
//  - _layout-footer.less
// @footer-bg-color: 			@site-color-primary;
// end moved code ⏩⏩⏩
// 💀💀💀💀 REMOVED 💀💀💀💀
// Removed because:
//  - Too many drawbacks to this method
// @extended-footer-bg-color: 	@site-color-primary; // page color if the viewport is taller than the content
// end removed code 💀💀💀💀


// Link Colors
// --------------------------
// ⏩⏩⏩ MOVED ⏩⏩⏩
// Moved to:
//  - a dedicated _links.less Core file
// > > > > > > > > > > > > > > > > > > > > > > > >
// @link-color:				@site-color-primary;
// @link-highlight:			lighten(@link-color, 10%);
// @link-shade:				darken(@link-color, 20%);
// //	|	|
// @link-secondary-color: 		@perch-color-secondary;
// @link-secondary-highlight:	lighten(@link-secondary-color, 10%);
// @link-secondary-shade:		darken(@link-secondary-color, 40%);
// //	|	|
// @link-tertiary-color: 		@site-color-tertiary;
// @link-tertiary-highlight:	lighten(@link-tertiary-color, 10%);
// @link-tertiary-shade:		darken(@link-tertiary-color, 40%);
// //	|	|
// @link-light-color: 			@perch-color-secondary;
// @link-light-highlight:		lighten(@link-light-color, 10%);
// @link-light-shade:			darken(@link-light-color, 40%);
// //	|	|
// @link-grayed-color: 		@perch-color-gray;
// @link-grayed-highlight:		lighten(@link-grayed-color, 10%);
// @link-grayed-shade:			darken(@link-grayed-color, 40%);
// < < < < < < < < < < < < < < < < < < < < < < < <
// end moved code ⏩⏩⏩


// Feedback Colors
// --------------------------
@feedback-negative-color: 			#B94A48;
@feedback-positive-color: 			#55af4c;

// as in "a highlighter marker"
@feedback-highlighter-color: 		#FDEE80;

@feedback-warning-color: 			#dfb030;
@feedback-error-color: 				#B94A48;
@feedback-success-color: 			#55af4c;
@feedback-info-color: 				#65adbd;
@feedback-dev-color: 				#269;



// Calculations, site stuff...
// ---------------------------------------


// Default / Mobile header heights
// --------------------------
@utility-nav-height: 				0;
@header-body-height: 				60px;
@header-height: 					(@utility-nav-height + @header-body-height);


// Desktop header heights
// --------------------------
@utility-nav-desktop-height: 		25px;
@header-body-desktop-height: 		60px;
@navbar-desktop-height: 			60px;
@header-desktop-height: 			(@utility-nav-desktop-height + @header-body-desktop-height);


// Content vertical spacing
// --------------------------
// ⏩⏩⏩ MOVED ⏩⏩⏩
// Moved to:
//  - _utilities.less
// @content-vertical-spacing: 			5%;
// @content-vertical-spacing-locked: 	(@site-max-width * @content-vertical-spacing * .01);
// end moved code ⏩⏩⏩
