//
// WordPress Resets & Overrides
// --------------------------------------------------



// WP Admin Bar
// ---------------------------------------

// Variables
// --------------------------
@wp-admin-bar-height:				32px;
@wp-admin-bar-mobile-height:		46px;

@wp-admin-bar-desktop-bp:			782px;
// --------------------------

// Styles
// --------------------------

// Move the global header down the height of the WP admin bar
body.admin-bar {

	.global-header.show {
		margin-top: @wp-admin-bar-height;
	}

	// ** == WHEN WP CHANGES ITS ADMIN BAR FROM TALL-MOBILE TO SHORT-DESKTOP **
	@media (max-width: @wp-admin-bar-desktop-bp) {
		.global-header.show {
			margin-top: @wp-admin-bar-mobile-height;
		}
	}
	@media (max-width: @mobile-styles-max) {
		.global-outer-navigation-wrapper {
			margin-top: (@header-height + @wp-admin-bar-height);
		}
	}
		// In the event that the WP desktop bar is shown but the site is still determines itself as mobile
		@media (max-width: @mobile-styles-max) and (max-width: @wp-admin-bar-desktop-bp) {
			.global-outer-navigation-wrapper {
				margin-top: (@header-height + @wp-admin-bar-mobile-height);
			}
		}
}

// Override: ensure the WP Admin Bar stays position: fixed (as opposed to their style for making it absolute at 600px and below, as of this writing)
#wpadminbar {
	position: fixed !important;
}



// Post & Post Meta
// ---------------------------------------

// .entry-intro {
// 	//
// }

.post,
.search .page,
.widget-area {
	position: relative;
	// padding: @common-gutter;
	margin-bottom: @common-gutter;
	margin-bottom: @common-vertical-gutter;
}
	.post + .post {
		margin-top: (@common-gutter * 2);
		margin-top: (@common-vertical-gutter * 2);
	}

.entry-title {
	font-size: 2.5em;
}

.entry-meta {
	.clear_float ();
	padding: 5px 0;
	margin-top: -10px;
	margin-bottom: @common-gutter;
	border-top: 1px solid @line-divider-color;
	border-bottom: 1px solid @line-divider-color;

	font-size: 0.75em;

	.cat-links {
		float: left;
	}
	.comments-link {
		float: right;
	}
}

.wp-post-image {
	max-width: 100%;
	height: auto;
	margin-bottom: 1em;
}

.entry-content {
	.clear_float ();
	font-size: 1.0em;
	line-height: 1.5;
}

.entry-footer {
	padding-top: 0.5em;
	border-top: 1px solid @line-divider-color;
	margin-top: 1em;

	font-size: 0.75em;
	color: lighten(@global-text-color, 15%);
}


.post-date {
	position: absolute;
	top: @common-gutter;
	right: 0;

	line-height: 1;
	font-size: 1em;
	// color: @fc-gray;

	// Element children
	.day {
		position: relative;
		top: -4px;
		font-size: 3.3em;
		font-weight: bold;
	}
	.month {
		font-size: 1.45em;
		text-transform: uppercase;
	}
	.year {
		font-size: 1.3em;
	}
}


#nav-above,
#nav-below {
	.clear_float ();
	padding: 5px 0;

	font-size: 0.8em;

	// a {
	// 	color: @fc-gray;

	// 	&:focus,
	// 	&:hover {
	// 		color: @fc-orange;
	// 	}
	// }
}
#nav-below {
	margin-top:   -10px;
	margin-bottom: 10px;
}

.nav-previous {
	float: left;
}

.nav-next {
	float: right;
}



// Sidebar
// ---------------------------------------
#sidebar .global-search:focus {
	width: 185px;
}

// .widget-area {
// 	// margin-left: @common-gutter;
// }

.sid {
	padding-left: 0;
	list-style: none;

	ul {
		margin-top: 10px;
		padding-left: 0;
	}
}

.widget-container {
	margin-bottom: (@common-gutter * 1);
	list-style: none;

	li {
		margin: 4px 0;
		list-style: none;

		font-size: 0.9em;
	}
	a {
		font-style: italic;
		// color: @fc-gray;

		// &:focus,
		// &:hover {
		// 	color: @fc-orange;
		// }
	}
}

.widget-title {
	margin-bottom: 10px;

	// font-family: @font-family-clean;
	font-size: 1.2em;
	font-weight: normal;
}



// Comments
// ---------------------------------------

// Variables
// --------------------------
@comments-section-font-size: 				0.9em;

@comment-top-level-bg-color: 				#F7F7F7;
@comment-child-level-bg-color: 				#FFFFFF;
@comment-child-border: 						1px solid #ccc;
// --------------------------


// Styles
// --------------------------
#comments {
	margin-bottom: @common-gutter;

	font-size: @comments-section-font-size;
}

#comments-list {
	// font-size: 0.95em;

	ul {
		padding-left: 0;

		> li {
			list-style: none;
		}
	}

	.comment {
		padding: (@common-gutter / 2);
		margin-bottom: @common-gutter;

		background-color: @comment-top-level-bg-color;

		// .children {
		// 	//
		// }

		// Nested comment coloring
		.comment {
			border: @comment-child-border;
			background-color: @comment-child-level-bg-color;

			.comment {
				background-color: @comment-top-level-bg-color;

				.comment {
					background-color: @comment-child-level-bg-color;
				}
			}
		}
		.avatar {
			max-width: 40px;
			height: auto;
		}
		.comment-meta {
			padding-bottom: 4px;
			border-bottom: 1px solid fade(#000, 7%);
			margin-bottom: 4px;

			font-size: 0.85em;
		}
	}
}

.comment-reply-link {
	display: block;
	font-size: 1.05em;

	@media (min-width: @screen-sm-min) {
		float: right;
	}
}

// Write a comment section
// --------------------------
#respond {
	//
	p {
		margin-top: 0;
	}
	textarea,
	input[type="text"],
	input[type="email"],
	input[type="url"] {
		.input-field;
		margin-bottom: 0;
	}
}



// Caption & Alignment
// ---------------------------------------

// Variables
// --------------------------
@wp-caption-wrapper-bg-color: 				#EAEAEA;
@wp-caption-wrapper-padding: 				5px;

@wp-caption-alignment-min-width: 			@screen-sm-min;

@wp-caption-text-font-size: 				0.85em;
@wp-caption-text-font-style: 				italic;
@wp-caption-text-font-weight: 				@font-weight-base;
@wp-caption-text-color: 					#888888;
// --------------------------


// Styles
// --------------------------
.wp-caption {
	max-width: 100%;
	padding: @wp-caption-wrapper-padding;
	background-color: @wp-caption-wrapper-bg-color;

	> img {
		max-width: 100%;
		height: auto;
	}
}

.wp-caption-text,
.entry-caption,
.gallery-caption {
	padding: 0;

	font-size: @wp-caption-text-font-size;
	font-style: @wp-caption-text-font-style;
	font-weight: @wp-caption-text-font-weight;
	color: @wp-caption-text-color;
}

// Non-floated alignment
// --------------------------
.alignnone {
	display: block;
}
img.alignnone {
	margin: 5px 0;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
img.aligncenter {
	margin: 5px auto;
}

// Floated alignment: only apply at wide enough widths
// --------------------------
@media (min-width: @wp-caption-alignment-min-width) {
	.alignleft,
	.alignright {
		max-width: 50%;
	}
	.alignleft {
		float: left;

		.alignleft {
			float: none;
			max-width: 100%;
			padding: 0;
			margin: auto;
		}
	}
	.alignright {
		float: right;

		.alignright {
			float: none;
			max-width: 100%;
			padding: 0;
			margin: auto;
		}
	}
	img.alignleft {
		margin: 5px 20px 5px 0;
	}
	.wp-caption.alignleft {
		margin: 5px 10px 5px 0;
	}
	img.alignright {
		margin: 5px 0 5px 20px;
	}
	.wp-caption.alignright {
		margin: 5px 0 5px 10px;
	}
	.wp-caption.alignleft + ul,
	.wp-caption.alignleft + ol  {
		list-style-position: inside;
	}
}



// Media Elements
// ---------------------------------------
.hentry .mejs-mediaelement,
.hentry .mejs-container .mejs-controls {
	background: #220e10;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-loaded,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
	background: #fff;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-current {
	background: #ea9629;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-total,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
	background: #595959;
}

.hentry .mejs-controls .mejs-time-rail span,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
	border-radius: 0;
}



// Utilities
// ---------------------------------------
.screen-reader-text {
	.sr-only;
}
