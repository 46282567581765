//
// Embed Wrapper
// --------------------------------------------------

// Styles
// ---------------------------------------
.embed-wrapper {
	position: relative;
	max-width: 100%;
	height: 0;
	// Standard video size (16:9)
	padding-top: (9 / 16 * 100%);
	margin-bottom: @common-vertical-gutter;
	overflow: hidden;

	// Extensions & Variations
	// ---------------------------------------
	// Television ratio (4:3)
	&.embed--4x3 {
		padding-top: (3 / 4 * 100%);
	}
	// Anamorphic widescreen ratio (2.39:1)
	&.embed--anamorphic_widescreen {
		padding-top: (1 / 2.39 * 100%);
	}

	// Position the iframe (or embed-object)
	> iframe,
	.embed-object {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
		margin: 0;
	}
}
