//
// Layout - Header
// --------------------------------------------------



// Header
// ---------------------------------------

// Variables
// --------------------------
@header-position: 					fixed;
@header-top: 						-(@header-height);
@header-left: 						0;
@header-right: 						0;
@header-z-index: 					5;

// @header-height: 					»»» set in variables.less
@header-line-height: 				@header-height;
@header-padding: 					@utility-nav-height 0 0;

@header-bg-color: 					@site-color-primary;
@header-text-color: 				#FFF;

@header-transition: 				top .3s ease-out,
									background-color .4s ease-out;

// States
@header-show-top: 					0;
// --------------------------

// Styles
// --------------------------
.global-header {
	position: @header-position;
	top: @header-top;
	left: @header-left;
	right: @header-right;
	z-index: @header-z-index;

	height:      @header-height;
	line-height: @header-line-height;
	padding: @header-padding;

	background-color: @header-bg-color;
	.font-smoothing ();
	color: @header-text-color;

	.transition (@header-transition);

	// States _________
	&.fast {
		.transition (none);
	}
	&.show {
		top: @header-show-top;
	}
	// &.show-not-top {
	// 	background-color: fade(@header-bg-color, 90%);
	// }
}



// Navigation Wrapper
// ---------------------------------------

// Variables
// --------------------------
@global-nav-bg-color: 								@site-color-primary;

@global-outer-navigation-wrapper-z-index: 			10;
@global-outer-navigation-wrapper-bg-color: 			fade(@global-nav-bg-color, 92%);
@global-outer-navigation-wrapper-animation-in: 		fadeIn .25s ease;
@global-outer-navigation-wrapper-animation-out: 	fadeOut .25s ease 0s 1 normal forwards;
// --------------------------

// Styles
// --------------------------
.global-outer-navigation-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: @global-outer-navigation-wrapper-z-index;

	margin: @header-height 0 0;

	background-color: @global-outer-navigation-wrapper-bg-color;

	// hiding
	max-height: 0;
	overflow-y: hidden;

	// Children
	// --------------------------
	> .container {
		padding-left: 0;
		padding-right: 0;
	}

	// States
	// --------------------------
	// Unhide when this class is present
	&.is-active {
		bottom: 0;
		max-height: none;
		overflow-y: scroll;

		.animation(@global-outer-navigation-wrapper-animation-in);
	}

	// Helper class from JS
	&.animating-out {
		.animation(@global-outer-navigation-wrapper-animation-out);
	}
}



// Base Nav styles
// ---------------------------------------

// Variables
// --------------------------
@global-nav-padding: 						0;
@global-nav-margin: 						0;

@global-nav-list-animation-in: 				fadeInUp .4s ease;

@global-nav-item-padding: 					0;
@global-nav-item-margin: 					0;

// nav list
@global-nav-list-text-align: 				left;

// nav link
@global-nav-link-padding: 					0.6em 1em;
@global-nav-link-line-height: 				1.2em;
@global-nav-link-font-size: 				1.1em;
@global-nav-link-text-transform: 			none;
@global-nav-link-font-weight: 				normal;
@global-nav-link-text-decoration: 			none !important;
@global-nav-link-color: 					#FFF;

@global-nav-link-highlight-bg-color: 		@ui-shade-bg-color;
@global-nav-link-highlight-color: 			@global-nav-link-color;
@global-nav-active-parent-bg-color: 		@global-nav-link-highlight-bg-color;
@global-nav-active-parent-text-color: 		@global-nav-link-highlight-color;
// --------------------------

// Styles
// --------------------------
.global-nav {
	padding: @global-nav-padding;
	margin: @global-nav-margin;
}

.global-nav-list {
	// Reset list stlyes
	margin: 0;
	padding: 0;
	list-style: none;

	text-align: @global-nav-list-text-align;

	.global-outer-navigation-wrapper.is-active & {
		.animation(@global-nav-list-animation-in);
	}
}

.global-nav-item {
	padding: @global-nav-item-padding;
	margin: @global-nav-item-margin;

	// Pretty sure this should be totally removed from Perch, but commenting out/deprecating for a release or two. I think this should be implemented as an extension rather than core, if anything. Also I'd call it "is-current-page" rather than "is-active", which could mean a variety of different things. --> KB 2016-06-14 4:52 PM
	// // The active page gets a changed link color beneath
	// &.is-active {
	// 	> .global-nav-link {
	// 		color: @light-link-color;

	// 		&::before {
	// 			content: "» ";
	// 		}
	// 	}
	// }
}

.global-nav-link {
	display: block;
	padding: @global-nav-link-padding;
	line-height: @global-nav-link-line-height;

	font-size: @global-nav-link-font-size;
	text-transform: @global-nav-link-text-transform;
	font-weight: @global-nav-link-font-weight;
	text-decoration: @global-nav-link-text-decoration;
	color: @global-nav-link-color;

	// Conditional Parentage styles
	// --------------------------

	// Active Parent & Active Page _________
	.active-parent > &,
	.active > & {
		background-color: @global-nav-active-parent-bg-color;

		color: @global-nav-active-parent-text-color;
	}

	// States
	// --------------------------
	.global-nav-item:focus > &,
	.global-nav-item:hover > & {
		background-color: @global-nav-link-highlight-bg-color;
		color: @global-nav-link-highlight-color;
	}
}



// Utility Nav
// ---------------------------------------

// Variables
// --------------------------

// outer nav element
// @utility-nav-height 					»»» set in variables.less
@utility-nav-bg-color: 					#333;

// outer nav container
@utility-nav-container-padding: 		0;

// nav item
@utility-nav-item-padding: 				0;
@utility-nav-item-margin: 				0;
@utility-nav-item-border-top: 			0;
@utility-nav-item-border-bottom: 		0;

// nav link
@utility-nav-link-height: 				auto;
@utility-nav-link-line-height: 			1;
@utility-nav-link-padding: 				@global-nav-link-padding;
@utility-nav-link-font-family: 			inherit;
@utility-nav-link-font-size: 			0.9em;
@utility-nav-link-text-transform: 		none;
@utility-nav-link-font-weight: 			normal;
@utility-nav-link-color:				#FFF;

@utility-nav-link-bg-highlight: 		fade(#000, 30%);
@utility-nav-link-highlight: 			#FFF;
// --------------------------

// Styles
// --------------------------
.global-utility-nav {
	background-color: @utility-nav-bg-color;

	// Children _________
	> .container {
		padding: @utility-nav-container-padding;
	}
}

.global-utility-nav-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.global-utility-nav-item {
	padding: @utility-nav-item-padding;
	margin: @utility-nav-item-margin;
	border-top: @utility-nav-item-border-top;
	border-bottom: @utility-nav-item-border-bottom;
}

.global-utility-nav-link {
	display: block;
	height: @utility-nav-link-height;
	line-height: @utility-nav-link-line-height;
	padding: @utility-nav-link-padding;

	font-family: @utility-nav-link-font-family;
	font-size: @utility-nav-link-font-size;
	text-transform: @utility-nav-link-text-transform;
	font-weight: @utility-nav-link-font-weight;
	color: @utility-nav-link-color;

	// States _________
	.global-nav-item:focus > &,
	.global-nav-item:hover > & {
		background-color: @utility-nav-link-bg-highlight;
		color: @utility-nav-link-highlight;
	}
}



// Main Nav
// ---------------------------------------

// Variables
// --------------------------
@global-main-nav-item-border-top: 				0;
@global-main-nav-item-border-bottom: 			0;
// --------------------------

// Styles
// --------------------------
// .global-main-nav {
// 	//
// }

// .global-main-nav-list {
// 	//
// }

.global-main-nav-item {
	border-top: @global-main-nav-item-border-top;
	border-bottom: @global-main-nav-item-border-bottom;
}

// .global-main-nav-link {
// 	//
// }



// Child Lists
// ---------------------------------------

// Variables
// --------------------------
@global-nav-child-list-padding: 				0;
@global-nav-child-list-border-top: 				0;
@global-nav-child-list-border-bottom: 			0;
@global-nav-child-list-margin: 					0;

@global-nav-child-link-padding-left: 			2em; // controls the indent
@global-nav-child-link-font-size: 				1em;

@global-nav-child-list-bg-color: 				@global-nav-link-highlight-bg-color;
// --------------------------

// Styles
// --------------------------
.global-nav-child-list {
	// hiding method
	max-height: 0;
	max-width: 0;
	overflow: hidden;

	padding: @global-nav-child-list-padding;
	border-top: @global-nav-child-list-border-top;
	border-bottom: @global-nav-child-list-border-bottom;
	margin:  @global-nav-child-list-margin;
	list-style-type: none;

	background-color: @global-nav-child-list-bg-color;

	// Animations reset since they already move along with the parent
	.animation(none) !important;

	// Children _________
	.global-nav-link {
		padding-left: @global-nav-child-link-padding-left;
		font-size: @global-nav-child-link-font-size;
	}

	// States _________
	&.is-open,
	&.is-mobile-open {
		// unhiding / revealing
		max-height: none;
		max-width: 100%;
		overflow: visible;
	}
}



// Child List Opening Behaviors
// ---------------------------------------

// Nav Item Opens Child
// ---------------------------------------
// Setting this class means clicking the child anchor will open the child list
// ---------------------------------------
// .global-nav-item--opens-child {
// 	// By default this doesn't change any styles
// }


// Nav Item Has Child Nav Opener
// ---------------------------------------
// Setting global-nav-item--has-child-nav-opener generates a .child-nav-opener to open the child list
// ---------------------------------------

// Variables
// --------------------------
@child-nav-opener-width: 						3em;
@child-nav-opener-height: 						auto;
@child-nav-opener-top: 							0;
@child-nav-opener-right: 						0;
@child-nav-opener-bottom: 						0;
@child-nav-opener-z-index: 						2;
@child-nav-opener-padding: 						0;
@child-nav-opener-margin: 						0;

@child-nav-opener-background: 					fade(#000, 20%);
@child-nav-opener-active-background: 			fade(#000, 25%);

@child-nav-opener-triangle-width: 				10px;
@child-nav-opener-triangle-height: 				7px;
@child-nav-opener-triangle-color: 				#444;
@child-nav-opener-triangle-visible-transform: 	scale(1);
@child-nav-opener-triangle-hiding-transform:  	scale(0);
@child-nav-opener-triangle-transition:  		all 0.3s ease;

@child-nav-opener-active-height: 				3em;
// --------------------------

// Styles
// --------------------------
.global-nav-item--has-child-nav-opener {
	position: relative;

	// create space for the .child-nav-opener button
	padding-right: (@child-nav-opener-width + 0.5);
}

// The button itself
.child-nav-opener {
	position: absolute;
	top: @child-nav-opener-top;
	right: @child-nav-opener-right;
	bottom: @child-nav-opener-bottom;
	z-index: @child-nav-opener-z-index;

	width: @child-nav-opener-width;
	height: @child-nav-opener-height;
	padding: @child-nav-opener-padding;
	margin:  @child-nav-opener-margin;
	border: 0;

	text-align: center;
	line-height: 1;
	background: @child-nav-opener-background;

	// State character icons _________
	> .closed-state,
	> .opened-state {
		position: absolute; // allows the two different spans to stack on top of one another
		left: 0;
		right: 0;
		margin: auto;
		margin-top: -(@child-nav-opener-triangle-height / 2); // manual centering of triangle

		&::after {
			content: "";

			display: block; // helps the centering positioning
			margin: auto;

			// CSS Triangle
			width: 0;
			height: 0;
			border-right:   (@child-nav-opener-triangle-width / 2) solid transparent;
			border-left:    (@child-nav-opener-triangle-width / 2) solid transparent;

			.transition(@child-nav-opener-triangle-transition);
		}
	}
	> .closed-state::after {
		.transform(@child-nav-opener-triangle-visible-transform);
		opacity: 1;

		// CSS Triangle -- border in the opposite direction it's "pointing"
		border-top:     @child-nav-opener-triangle-height solid @child-nav-opener-triangle-color;
	}
	> .opened-state::after {
		.transform(@child-nav-opener-triangle-hiding-transform);
		opacity: 0;

		// CSS Triangle -- border in the opposite direction it's "pointing"
		border-bottom:     @child-nav-opener-triangle-height solid @child-nav-opener-triangle-color;
	}

	// States _________
	&.is-active {
		height: @child-nav-opener-active-height;

		background: @child-nav-opener-active-background;

		// Toggle visibility of the two state elements
		> .closed-state::after { .transform(scale(0)); opacity: 0; }
		> .opened-state::after { .transform(scale(1)); opacity: 1; }
	}
}


// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
//  - Lots of complexity for a super rare case.
// 	- present implementations of nav are still in a kind of flux... likely any work here will need a big rewrite at some point soon

// // Grandchild Lists
// // ---------------------------------------

// // Variables
// // --------------------------
// @global-nav-grandchild-link-padding-left: 		3em; // controls the indent
// // --------------------------

// // Styles
// // --------------------------
// .global-nav-list .global-nav-child-list .global-nav-child-list {
// 	//
// 	.global-nav-link {
// 		padding-left: @global-nav-grandchild-link-padding-left;
// 	}
// }

// end deprecated code ☠☠☠☠



//
// Global Logo
// --------------------------------------------------
// »»» Moved to _global-logo.less
