//
// Layout - Footer
// --------------------------------------------------

// Variables
// ---------------------------------------
@footer-padding-top: 				@content-vertical-spacing;
@footer-padding-bottom: 			@content-vertical-spacing;

@footer-bg-color: 					@site-color-primary;
// ---------------------------------------

// Styles
// ---------------------------------------
.global-footer {
	position: relative;
	z-index: 2;

	padding-top: @footer-padding-top;
	padding-bottom: @footer-padding-bottom;

	background-color: @footer-bg-color;
	color: #fff;

	@media (min-width: @content-vertical-spacing-lock-vw) {
		padding-top: @content-vertical-spacing-locked;
		padding-bottom: @content-vertical-spacing-locked;
	}
}
