//
// Site Layout - Layout overrides & unique styles
// --------------------------------------------------



// Containers
// ---------------------------------------
.container-xxl {
	width: auto;
	max-width: @container-xxl;
}



// Header
// ---------------------------------------
@header-position: 				absolute;

.global-header {
	background-image: url('../images/bg-nav-ss.jpg');
	background-position: 50%;
}

body.admin-bar .global-header.show {
	margin-top: 0;
}

@media screen and (max-width: 625px) {
	.global-header {
		background-position: 40%;
	}
}

@media (min-width: 830px) {
	.home {
		.global-header {
			background: transparent;
		}
		.global-main-nav-link {
			color: #ffffff;
		}
		.global-main-nav-link:hover,
		.global-main-nav-link:active  {
			color: #757575; /*#51adef*/
		}
		.menu-home.active > a {
			color: #1E5294 !important;
			font-weight: 700;
		}
	}
}


@media (min-width: @screen-xl-min) {
	.global-main-nav-link {
		font-size: 1.2em;
	}
}


// Logo
// ---------------------------------------
.global-logo {
	color: #00ff1f;
}

@media screen and (min-width: 1260px) {
	.global-logo {
		width: 300px;
		top: 38px;
		left: 30px
	}
}



// Navigation
// ---------------------------------------


// Search Reveal Button
// ---------------------------------------
@search-reveal-button-right: 			@hamburger-button-width;



// Footer
// ---------------------------------------
.global-footer {
	.global-footer-copyright {
		font-size: 12px;
		padding: 5% 0;
	}
	.footer-body {
		padding: 5% 0%;
		// font-size: .9em;
		// min-height: 290px;
	}
	// .footer-body .container {
	// 	padding-right: 1%;
	// 	padding-left: 4%;
	// }
	.footer-body h4 {
		text-transform: uppercase;
		font-size: 1em;
	}

	.footer-body .global-footer-link-list {
		list-style: none;
		padding: 0;
		margin-bottom: @common-gutter;
	}
	// .footer-body li {

	// }
	.footer-body li.icons {
		padding-top: 7px;
		&:first-child { padding-top: 0; }
	}
	.footer-body .global-footer-link-list > li {
		font-size: 0.9em;
	}
	.footer-body .global-footer-link-list a {
		padding-left: 5%;
	}
	.address {
		text-align: left;
		// padding-left: 1%;
		display: inline-block;
		vertical-align: middle;
		line-height: 1.3;
	}
	.contact-list {
		padding-left: 5%;
		font-size: 0.8em;
	}
	.highlight-blue {
		color: #757575;
	}
	.footer-logo {
		display: block;
		margin-bottom: @common-gutter;
		> img {
			width: 100%;
			max-width: 280px;
		}
	}

	.footer-bottom {
		padding: 0;
		background-image: url('../images/bg-nav-ss.jpg');
		background-position: 0% 50%;
		color: #555;
		height: 35px;
	}
	.footer-bottom-seal {
		margin: 0 auto;
		width: 140px;
		z-index: 5000;
	}
	.footer-bottom-seal img {
		margin: -40px;
		width: 100%;
	}
	// .icons {
	// 	// padding-bottom: 7%;
	// }
	.icon {
		vertical-align: middle;
		font-size: 1.7em;
	}
}

@media screen and (max-width: 625px) {
	.global-footer {
		.global-footer-copyright{
			font-size: 12px;
			padding-bottom: 15%;
			text-align: center;
		}
		.footer-body {
			text-align: center;
			// font-size: inherit;
			// padding: 4% 9%;
		}
		.footer-logo {
			// padding: 10px 0 30px;

			> img {
				width: 100%;
				max-width: 240px;
				// transform: translateX(-30px);
			}
		}
		.footer-body h4 {
			text-transform: uppercase;
			font-size: 1.6em;
			text-align: left;
		}

		.footer-body .global-footer-link-list {
			list-style: none;
			// padding: 0 0 32px 0;
			text-align: left;
		}
		// .footer-body li {
		// 	padding-bottom: 5%;
		// 	font-size: 1em;
		// }
		// .footer-body li.icons {
		// 	padding-top: 7px;
		// 	&:first-child { padding-top: 0; }
		// }
		// .address {
		// 	// text-align: left;
		// 	// padding-left: 4%;
		// 	// display: inline-block;
		// 	// vertical-align: middle;
		// 	// line-height: 1.3;
		// }
		// .icon {
		// 	font-size: 1.7em;
		// 	vertical-align: middle;
		// }
		// .icon-location {
		// 	vertical-align: text-top;
		// 	font-size: 2.3em;
		// 	margin-right: -5px;
		// }
		// .icon-phone {
		// 	font-size: 1.9em;
		// }
		// .footer-column-four {
		// 	margin-bottom: -30px;
		// }
		// .icons {
		// 	// padding-bottom: 10% !important;
		// }
	}
}

@media screen and (min-width: 626px) and (max-width: 769px) {
	.global-footer {
		// .footer-body li {
		// 	// font-size: 0.85em;
		// }
		.global-footer-copyright{
			padding: 15% 0;

			text-align: center;
			font-size: 0.85em;
		}
		// .footer-body {
		// 	// min-height: 200px;
		// 	// padding: 4% 0%;

		// 	// font-size: .9em;
		// }
	}
}
//Special padding for the footer because of ISO logo in bottom footer
@media (min-width: 1260px) {
	.global-footer {
	 	padding-bottom: 0;
	 }
	.footer-logo img {
		max-width: 230px;
	}
}
