//
// Breadcrumbs
// --------------------------------------------------

// Variables
// ---------------------------------------
@breadcrumbs-wrapper-margin: 			auto;

@breadcrumbs-font-size: 				1em;
@breadcrumbs-margin-bottom: 			(@common-gutter / 2);

@breadcrumbs-link-color: 				inherit;

@breadcrumbs-divider-character: 		"\00BB";
@breadcrumbs-divider-font-size: 		1.25em;
@breadcrumbs-divider-font-weight: 		normal;
@breadcrumbs-divider-color: 			#AAA;
// ---------------------------------------


// Styles
// ---------------------------------------

// Outermost <nav> wrapper
.breadcrumbs-wrapper {
	margin: @breadcrumbs-wrapper-margin;
}

// Wrapping <ol>
.breadcrumbs {
	list-style: none;

	padding: 0;
	margin: 0 0 @breadcrumbs-margin-bottom;

	font-size: @breadcrumbs-font-size;
}

// Each <li> wrapping item
.breadcrumb-item {
	display: inline-block;

	// Divider
	&:after {
		display: inline-block;
		content: @breadcrumbs-divider-character;
		padding: 0 0.1em 0 0.25em;

		font-size: @breadcrumbs-divider-font-size;
		font-weight: @breadcrumbs-divider-font-weight;
		color: @breadcrumbs-divider-color;
	}
		// No divider after last element
		&:last-child:after {
			display: none;
			content: "";
		}
}

// The <a> breadcrumb itself
.breadcrumb {
	color: @breadcrumbs-link-color;
}
