//
// Tables
// --------------------------------------------------

// Variables
// ---------------------------------------
@table-border-color: 					#999;
@table-border-width: 					1px;
@table-common-border-shorthand: 		@table-border-width solid @table-border-color;

@table-cell-padding: 					5px;
@table-cell-vertical-align: 			top;
@table-th-bg-color: 					fade(#000, 15%);

@table-striped-bg-color: 				fade(#000, 6.5%);
// ---------------------------------------

// Styles
// ---------------------------------------
table {
	border-top:    @table-common-border-shorthand;
	border-left:   @table-common-border-shorthand;
}

th,
td {
	padding: @table-cell-padding;
	border-right:  @table-common-border-shorthand;
	border-bottom: @table-common-border-shorthand;
	vertical-align: @table-cell-vertical-align;
}

th {
	background-color: @table-th-bg-color;
}

// Table extensions
// --------------------------
.table--striped {
	tbody tr:nth-child(even) {
		background-color: @table-striped-bg-color;
	}
}

.table--borderless {
	border: 0;

	th,
	td {
		border: 0;
	}
}
