//
// Helpers
// --------------------------------------------------
// Classes used for aiding front-end development that
// can assist in defining or restyling elements w/out
// needing to be full-blown overrides.
// --------------------------------------------------
// For !important using override classes, see
// _utilities.less.
// --------------------------------------------------



// Screenreader only
// --------------------------------------------------
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
	border: 0;
}



// Clearfixing
// --------------------------------------------------
// .clear_float() is the LESS mixin (_mixins.less);
// .clearfix is a helper CSS class
// --------------------------------------------------
.clearfix {
	.clear_float();
}



// Stripped Lists
// --------------------------------------------------
// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
//  - class name not consistent with Perch
//  - stripped is a bit clearer
.list-bare,
// end deprecated code ☠☠☠☠
.list--stripped {
	.strip_list ();
}



// Common Content Vertical Spacing
// --------------------------------------------------
// A reusable set of helpers for defining vertical
// spacing with percentages, yet capping those
// percentages at set viewport widths.
// --------------------------------------------------

// Variables
// ---------------------------------------
@content-vertical-spacing-lock-vw: 		@site-max-width;
@content-vertical-spacing: 				5%;
@content-vertical-spacing-locked: 		(@content-vertical-spacing-lock-vw * @content-vertical-spacing / 100);
// ---------------------------------------


// Styles
// ---------------------------------------
.common-content-vertical-spacing {
	padding-top:    @content-vertical-spacing;
	padding-bottom: @content-vertical-spacing;

	@media (min-width: @content-vertical-spacing-lock-vw) {
		padding-top:    @content-vertical-spacing-locked;
		padding-bottom: @content-vertical-spacing-locked;
	}
}
.common-content-vertical-spacing-top {
	padding-top:    @content-vertical-spacing;

	@media (min-width: @content-vertical-spacing-lock-vw) {
		padding-top:    @content-vertical-spacing-locked;
	}
}
.common-content-vertical-spacing-bottom {
	padding-bottom: @content-vertical-spacing;

	@media (min-width: @content-vertical-spacing-lock-vw) {
		padding-bottom: @content-vertical-spacing-locked;
	}
}



// Images
// --------------------------------------------------
.img-fluid, // BS4 class name
.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}



// SVGs
// --------------------------------------------------
.svg-wrapper {
	display: block;

	> svg {
		max-width: 100%;
		height: auto;
	}
}



// JS toggles
// --------------------------------------------------
.is-mobile-scroll-locked {
	overflow: hidden;
	// position: fixed; // 2018-01-02 removed because this was causing all versions of WebKit to fail: the mobile navigation would no longer show.
	height: 100%;
	width: 100%;

	// In landscape views this seems to zoom way in to the page. So this fixes it
	@media all and (orientation: landscape) {
		position: static;
		height: auto;
		width: auto;
	}
	@media (min-width: @desktop-styles-min) {
		overflow: visible;
		position: relative;
		height: auto;
		width: auto;
	}
}



// Make full width
// --------------------------------------------------
// Useful for breaking elements out of containers
// NOTE: this assumes that the container is centered.
// --------------------------------------------------
.make-full-width {
	// Make width full viewport width
	width: 100vw;
	// Allow for relative positioning
	position: relative;
	// Relative to its container width, it is pushed 50% to the left
	left: 50%;
	// Relative to its own width (100vw), it is pulled back 50% to the left
	transform: translate(-50%);
}
