//
// Parsely.js form validation styles
// --------------------------------------------------
// A manual re-styling & customization of parsely's css
// --------------------------------------------------

// Variables
// ---------------------------------------
@parsley-success-border-color: 			@feedback-success-color;
@parsley-success-bg-color: 				lighten(@feedback-success-color, 45%);
@parsley-success-text-color: 			darken(@feedback-success-color, 10%);

@parsley-error-border-color: 			@feedback-error-color;
@parsley-error-bg-color: 				lighten(@feedback-error-color, 45%);
@parsley-error-text-color: 				darken(@feedback-error-color, 10%);

@parsley-errors-list-padding: 			0;
@parsley-errors-list-margin: 			2px 0 3px;
@parsley-errors-list-font-size: 		0.9em;
@parsley-errors-list-line-height: 		0.9em;
@parsley-errors-list-transition: 		all .3s ease-in;
// ---------------------------------------


// Styles
// ---------------------------------------
// .parsley-validated {}

.parsley-success {
	border: @input-border-width solid @parsley-success-border-color !important;

	background-color: @parsley-success-bg-color !important;
	color: @parsley-success-text-color !important;
}
.parsley-error {
	border: @input-border-width solid @parsley-error-border-color !important;

	background-color: @parsley-error-bg-color !important;
	color: @parsley-error-text-color !important;
}

.parsley-errors-list {
	padding: @parsley-errors-list-padding;
	margin: @parsley-errors-list-margin;

	list-style-type: none;

	font-size: @parsley-errors-list-font-size;
	line-height: @parsley-errors-list-line-height;
	opacity: 0;

	transition: @parsley-errors-list-transition;

	// States
	// --------------------------
	&.filled {
		opacity: 1;
	}
}
