@icomoon-font-path: "fonts";

@icon-checkmark: "\e901";
@icon-filter: "\e902";
@icon-google-plus: "\e903";
@icon-google-plus-alt: "\e904";
@icon-pinterest: "\e634";
@icon-pinterest-alt: "\e635";
@icon-youtube: "\e636";
@icon-mail: "\e601";
@icon-twitter: "\e602";
@icon-twitter-alt: "\e603";
@icon-share: "\e608";
@icon-file-pdf: "\e60b";
@icon-warning-stop: "\e60d";
@icon-exit: "\e60f";
@icon-info: "\e612";
@icon-question: "\e613";
@icon-warning: "\e614";
@icon-alert: "\e615";
@icon-blocked: "\e616";
@icon-link: "\e61c";
@icon-search: "\e622";
@icon-zoom-in: "\e623";
@icon-zoom-out: "\e624";
@icon-print: "\e625";
@icon-download: "\e62b";
@icon-calendar: "\e631";
@icon-chevron-down: "\f0a3";
@icon-chevron-left: "\f0a4";
@icon-chevron-right: "\f078";
@icon-chevron-up: "\f0a2";
@icon-home: "\e63e";
@icon-linkedin: "\e63c";
@icon-linkedin-alt: "\e63d";
@icon-rarebird-mark: "\e639";
@icon-bubble: "\e96b";
@icon-plus: "\ea0a";
@icon-minus: "\ea0b";
@icon-facebook: "\ea90";
@icon-facebook2: "\ea91";
@icon-instagram: "\ea92";
@icon-phone: "\e942";
@icon-location: "\e947";

