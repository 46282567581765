//
// Figures
// --------------------------------------------------


// Variables
// ---------------------------------------
@figcaption-padding: 			0 5%;
@figcaption-font-size: 			0.8em;
@figcaption-text-color: 		lighten(@global-text-color, 10%);
// ---------------------------------------


// Styles
// ---------------------------------------
// figure {
// 	//
// }

figcaption {
	padding: @figcaption-padding;
	font-size: @figcaption-font-size;
	color: @figcaption-text-color;
}
