//
// Linked Images
// --------------------------------------------------


// Variables
// ---------------------------------------
@linked-images-transition: 				opacity 0.12s ease;
@linked-images-hover-opacity: 			0.8;
// ---------------------------------------


// Styles
// ---------------------------------------
a img {
	border: 0;

	transition: @linked-images-transition;
	// will-change: opacity;

	&:hover {
		border: 0;
		opacity: @linked-images-hover-opacity;
	}
}
