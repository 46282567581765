//
// Perch Normalize
// --------------------------------------------------
// *	Custom Normalization + resets, extending normalize.css
// *	Opinionated yet considered resets.
// --------------------------------------------------


// Box model change
// ---------------------------------------
*,
*::before,
*::after {
	.box-sizing (border-box);
}
	// There's an idea making the rounds, made it into BS4 (maybe BS3 too) that all items should inherit box-sizing, so setting it on a parent element will automatically reset it for all children. To me it doesn't make sense to do this. If I want it to inherit on a component or third party plugin, I'd rather manually specify that. But I'm open for debate. — @k_bav | October 16, 2015


// Body
// ---------------------------------------
body {
	background-color: #FFF; // If this is not set, IE and Edge on Windows will use the default window background color — a gray. Eww.
}


// Clickable elements get appropriate cursor
// ---------------------------------------
input[type="radio"],
input[type="checkbox"],
input[type="reset"],
input[type="button"],
input[type="submit"],
select,
option,
button,
label[for] {
	cursor: pointer;
}


// Remove Tap Delay
// ---------------------------------------
a,
area,
button,
[role=button],
input:not([type=range]),
label,
select,
summary,
textarea {
	-ms-touch-action: manipulation;
	    touch-action: manipulation;
}


// Disabled states
// ---------------------------------------
button[disabled],
html input[disabled],
select[disabled] {
	opacity: .5;
	cursor: default;
	cursor: not-allowed;
}


// Tables
// ---------------------------------------
table {
	border-collapse: collapse; // Prevent double borders
}


// Select element overrides
// ---------------------------------------
select {
	max-width: 100%;
	margin-left: 0;
}


// Search element
// ---------------------------------------
input[type="search"] {
	// Re-reset this. Normalize.CSS resets this to content-box; we want everything to be border-box.
	.box-sizing (border-box);
	// Remove the rounded search box with search icon default styling
	-webkit-appearance: none;
}


// Radio input styling overrides for position
// ---------------------------------------
input[type="radio"] { margin-left: 1px; margin-right: 3px; }


// Number inputs -- remove spinbox/spin buttons
// ---------------------------------------
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}


// Better figure element defaults
// ---------------------------------------
// I'd rather have the freedom to wrap a media element + caption with figure and figcaption without having to override default assumptions that the figure element is always floated, needs indented, etc. —> K October 22, 2015
// ---------------------------------------
figure {
	display: block;
	margin: 0 0 1em;
}

// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
//  - updated normalize.css now normalizes to using text-decoration
// Abbreviations and acronyms
// ---------------------------------------
// abbr[title] {
// 	text-decoration: none; // addresses Firefox adding a dotted underline decoration — which is correct, and what we'll normalize to at some point. (c.f. https://github.com/necolas/normalize.css/pull/451 )
// }
// end deprecated code ☠☠☠☠



// Common element layout
// ---------------------------------------

// Variables
// --------------------------
@common-element-margin-top: 		0;
@common-element-margin-bottom: 		@common-vertical-gutter;
// --------------------------

// Styles
// --------------------------
ul, ol, dd,
p, figure,
pre, table, fieldset, hr,
.content-block {
	margin-top:    @common-element-margin-top;
	margin-bottom: @common-element-margin-bottom;
}
