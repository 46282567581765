//
// Mixins
// --------------------------------------------------
// ===INDEX===
// 1. Basic functional mixins
// 2. Advanced functional mixins
// 3. Multi-vendor-prefix mixins
// --------------------------------------------------


// 1. Basic functional mixins
// --------------------------------------------------

// Float clearing
// ---------------------------------------
.clear_float () {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}


// Prevent bottom margin collapse
// ---------------------------------------
.prevent-bottom-margin-collapse() {
	&:after {
		content: "";
		display: table;
	}
}


// Strip (un)ordered list default styling
// ---------------------------------------
.strip_list () {
	padding: 0;
	list-style-type: none;

	li {
		list-style: none;
	}
}


// Hardware Acceleration
// ---------------------------------------
.hardware-accelerate () {
	// http://stackoverflow.com/questions/10014461/why-does-enabling-hardware-acceleration-in-css3-slow-down-performance?rq=1
		// prefixless because THANKS OBAMA/IE10
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	// http://stackoverflow.com/a/26371807/4486857
		// "...Even if an element has been given its own layer, transitions on non-compositing properties (width, height, left, top, etc) still _cannot be accelerated_, because they occur before the compositing stage"
	.transform (translate3d(0,0,0));
}


// Font Smoothing & Unsmoothing
// ---------------------------------------
.font-smoothing () {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.font-unsmoothing () {
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
}


// Turn on font kerning
// ---------------------------------------
.kern-me () {
	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	font-feature-settings: "kern" 1;
	font-kerning: normal;
}



// 2. Basic functional mixins
// --------------------------------------------------

// Background clip for text mixin
// ---------------------------------------
// Based on Divya's pure CSS solution:
// http://nimbupani.com/using-background-clip-for-text-with-css-fallback.html
// ---------------------------------------
.clip-text-with-linear-gradient(@top-color: #000, @bottom-color: #555, @fallback-color: #000) {
	color: @fallback-color;
	-webkit-text-fill-color: transparent;
	background: -webkit-linear-gradient(@top-color, @bottom-color);
	background: -o-linear-gradient(@top-color, @bottom-color);
	-webkit-background-clip: text;
}
.clip-text-with-image(@img-url, @fallback-color: #000) {
	color: @fallback-color;
	-webkit-text-fill-color: transparent;
	background: -webkit-linear-gradient(transparent, transparent),
	            url(@img-url) repeat;
	background: -o-linear-gradient(transparent, transparent);
	-webkit-background-clip: text;
}


// LESS Quantity queries!
// ---------------------------------------
// quantity-queries.less
// https://github.com/adjohnson916/quantity-queries.less
// Concept: http://alistapart.com/article/quantity-queries-for-css
// ---------------------------------------
._build-quantity-selector(@selector-append, @selector-sibling) {
	&@{selector-append},
	&@{selector-append} ~ @{selector-sibling} {
		@rules();
	}
}
//
.exactly(@count, @selector-sibling, @rules) {
	@selector-append: e(":nth-last-child(@{count}):first-child");
	._build-quantity-selector(@selector-append, @selector-sibling);
}

.at-least(@count, @selector-sibling, @rules) {
	@selector-append: e(":nth-last-child(n+@{count})");
	._build-quantity-selector(@selector-append, @selector-sibling);
}

.at-most(@count, @selector-sibling, @rules) {
	@selector-append: e(":nth-last-child(-n+@{count}):first-child");
	._build-quantity-selector(@selector-append, @selector-sibling);
}

.between(@first, @last, @selector-sibling, @rules) {
	@selector-append: e(":nth-last-child(n+@{first}):nth-last-child(-n+@{last}):first-child");
	._build-quantity-selector(@selector-append, @selector-sibling);
}
// Usage example:
// --------------------------
// Note --->
// 1. If using a class, the element needs "escaped"
//    so as to not confuse LESS
// --------------------------
//
// // 6 elements or greater
// .foo {
//     .at-least(6, ~".foo", {
//         background: green;
//     });
// };
//
// // 2 elements or fewer
// .foo {
//     .at-most(2, ~".foo", {
//         background: yellow;
//     });
// };
//
// // exactly 7 elements
// .foo {
//     .exactly(7, ~".foo", {
//         background: orange;
//     });
// };
//
// // between 4 and 5 elements
// .foo {
//     .between(4, 5, ~".foo", {
//         background: red;
//     });
// };


// Scrolling shadows
// ---------------------------------------
// Inspired by http://lea.verou.me/2012/04/background-attachment-local/
// Cf. http://codepen.io/kbav/pen/QyVjRz/
// ---------------------------------------
   @scrolling-shadow-indication-shadow: fade(#000, 20%);
.scrolling-shadow-indication (@bgcolor:#FFF, @shadow: @scrolling-shadow-indication-shadow, @cover-size: 40px, @shadow-size: 12px) {

	@bgcolor-fade: fade(@bgcolor, 0%);

	// First two gradients are the shadow covers; second two are the shadows themselves
	background:
		-webkit-linear-gradient(@bgcolor 30%, @bgcolor-fade) 0 0~"/"100% @cover-size no-repeat local,
		-webkit-linear-gradient(@bgcolor-fade, @bgcolor 70%) 0 100%~"/"100% @cover-size no-repeat local,
		-webkit-linear-gradient(@shadow, rgba(0,0,0,0)) 0 0~"/"100% @shadow-size no-repeat scroll,
		-webkit-linear-gradient(rgba(0,0,0,0), @shadow) 0 100%~"/"100% @shadow-size no-repeat scroll;
	background:
		linear-gradient(@bgcolor 30%, @bgcolor-fade) 0 0~"/"100% @cover-size no-repeat local,
		linear-gradient(@bgcolor-fade, @bgcolor 70%) 0 100%~"/"100% @cover-size no-repeat local,
		linear-gradient(@shadow, rgba(0,0,0,0)) 0 0~"/"100% @shadow-size no-repeat scroll,
		linear-gradient(rgba(0,0,0,0), @shadow) 0 100%~"/"100% @shadow-size no-repeat scroll;
}
// Sample Usage
// .scrolling-shadows {
// 	.scrolling-shadow-indication (@tile-gray, rgba(0,0,0,0.25), 36px, 14px);
// }


// Sequential Animation Delay
// ---------------------------------------
@build-has-mixin-sequential-animation-delay: true; // Feedback for mixin guard support

.sequential-animation-delay ( @items: 8; @base-delay: 0.2s; @delay-delta: 0.075s; @counter: 0 ) when (@counter < @items) {

	// print (just once thanks to "& when" condition) an animation delay to cover "all following items"
	// this is a fallback in case more items are added in the markup but this function's arguments aren't updated
	& when (@counter = (@items - 1)) {
		&:nth-child(n) {
			animation-delay: (@base-delay + @delay-delta * @items);
		}
	}

	// iterate
	.sequential-animation-delay (@items; @base-delay; @delay-delta; (@counter + 1));

	// printed code for each iteration
	@nth: @counter + 1; // CSS nth-child is not 0 based
	&:nth-child(@{nth}) {
		animation-delay: (@base-delay + @delay-delta * @counter);
	}
}

// Sample Usage
// --------------------------
// .item-listing__li {
// 	@base-delay: 3s;
// 	@items: 16;
// 	@delay-delta: 0.05s;

// 	// Base animation
// 	animation: fadeInUp .3s ease @base-delay 1 normal both;
// 	// Mixin for sequential animation delay
// 	.sequential-animation-delay (@items; @base-delay; @delay-delta);
// }


// Hide Trailing Items
// ---------------------------------------
// Warning: this whole concept falls apart if it's being applied to a list that _may or may not_ have the intended number of items. e.g., a product list in the design might have 8 items in it, but on the production site there may only be 5 items to list. At this point, the trailing items would be hidden. Not cool, and difficult to debug. Need to write a kind of inverse treatment to this... "show-first-number-of-items()" or something.
// ---------------------------------------
.hide-trailing-items ( @items; @display-property: block; @counter: 0 ) when (@counter <= @items) {

	// print once at the top, to reset all items to displaying
	& when (@counter = (@items - 1)), (@items = 0) {
		&:nth-child(n) {
			display: @display-property;
		}
	}

	// iteration
	.hide-trailing-items (@items; @display-property; (@counter + 1));

	// iterate through the specified number of trailing items
	&:nth-last-child(@{counter}) {
		display: none;
	}
}

// Sample Usage
// --------------------------

// Hide the last 3 items
// .hide-trailing-items(3);

// Hide the last 3 items at default size, 2 items at -md-, and 0 items at -lg-
// .item {
// 	.hide-trailing-items(3);

// 	@media (min-width: @screen-md-min) {
// 		.hide-trailing-items(2);
// 	}
// 	@media (min-width: @screen-lg-min) {
// 		.hide-trailing-items(0);
// 	}
// }

// Retain a property's default "display" value (eg. inline, inline-block, flex, list-item)
// .hide-trailing-items(3; list-item);



// Betwixt
// --------------------------------------------------
// A dynamic value calc() generator for CSS Locks
// --------------------------------------------------
// via https://css-tricks.com/between-the-lines/
// mixin code manually translated from SASS:
// 		http://codepen.io/tobystokes/pen/NjGOde
// For bugs or errors, consult the thorough & helpful
// 		https://fvsch.com/code/css-locks/
// --------------------------------------------------
.betwixt (@property, @from, @to, @fromWidth: @screen-sm-min, @toWidth: @screen-xl-min) {

	// First declare the "from" value, the mobile-first default value
	@{property}: @from;

	// Some calculations are performed...
	@slope: (@from - @to) / (@fromWidth - @toWidth);
	@base: @to - @slope * @toWidth;

	// Starting with this width, a calc() function scales the value up or down toward the "to" value
	@media (min-width: @fromWidth) {
		@{property}: calc(~"@{base} + "100vw * @slope);
	}

	// Upper end end of the "lock"
	@media (min-width: @toWidth) {
		@{property}: @to;
	}
}

// Sample Usage
// --------------------------

// // Basic
// h1 {
// 	.betwixt(font-size, 16px, 32px);
// }

// // Can pass variables
// h1 {
// 	.betwixt(font-size, @font-size-base, 32px);
// }

// // Can be used for any property that is looking for a px value
// .logo {
// 	.betwixt(width, 100px, 350px);
// }

// // Custom media queries can be used
// .logo {
// 	.betwixt(width, 100px, 350px, @site-min-width, @site-max-width);
// }


// Tests:
// --------------------------
// ✅ 1. Basics:
// 		a. betweening without MQ values declared in the mixin and with
//		b. betweening from high to low values
//		c. multiple betwixt's

// ✅ 2. Supported properties:
//		a. width, height, padding, margin, border-width, background-size

// ❌ 3. Supported units outside of px (pixel) units:
// 		a. unitless, em units, or non-pixel-based units (eg deg/rad/turn) don't work,
//			usually due to calc() restrictions
// 		b. also true for setting the Medua Queries
//			1. not recommended because em-based MQ's break when user changes their font size

// ❓ 4. Experiments / remaining:
//		a. Might be a good candidate for combining with CSS Variables/custom properties
// 			1. Particularly for betweening values like: `box-shadow: 0 0 --betwixtMeShadowSpread #000`
//		b. Could be fun to experiment with perspective-origin...


// Background color spin loop mixin
// ---------------------------------------
// When you want the background colors of a set to change from item to item
// ---------------------------------------
.background-color-spin-loop ( @base-color; @items; @spin-delta: 5deg; @counter: (@items - 1) ) when (@counter > 0) {

	// print (just once thanks to "& when" condition) the base color
	// this is a fallback in case more items are added in the markup but this function's arguments aren't updated
	& when (@counter = (@items - 1)) {
		background-color: @base-color;
	}

	// iterate
	.background-color-spin-loop(@base-color; @items; @spin-delta; (@counter - 1) );

	// printed code for each iteration
	@nth: @counter + 1; // CSS nth-child is not 0 based
	&:nth-child(@{nth}) {
		background-color: spin(@base-color, (@counter * @spin-delta));
	}
}


// (text) color spin loop mixin
// ---------------------------------------
// When you want the text colors of a set to change from item to item
// ---------------------------------------
.color-spin-loop ( @base-color; @items; @spin-delta: 5deg; @counter: (@items - 1) ) when (@counter > 0) {

	// print (just once thanks to "& when" condition) the base color
	// this is a fallback in case more items are added in the markup but this function's arguments aren't updated
	& when (@counter = (@items - 1)) {
		color: @base-color;
	}

	// iterate
	.color-spin-loop(@base-color; @items; @spin-delta; (@counter - 1) );

	// printed code for each iteration
	@nth: @counter + 1; // CSS nth-child is not 0 based
	&:nth-child(@{nth}) {
		color: spin(@base-color, (@counter * @spin-delta));
	}
}



// 3. Multi-vendor-prefix mixins
// ---------------------------------------
.box-sizing (@bsizing: border-box) {
	-webkit-box-sizing: @bsizing;
	   -moz-box-sizing: @bsizing;
	        box-sizing: @bsizing;
}

.box-shadow (@shadow: 0 2px 3px rgba(0,0,0,.3)) {
	// ☠☠☠☠ DEPRECATED ☠☠☠☠
	// -webkit-box-shadow: @shadow;
	// end deprecated code ☠☠☠☠
	        box-shadow: @shadow;
}

.filter (@filter) {
	-webkit-filter: @filter;
	        filter: @filter;
}

.backdrop-filter (@backdrop-filter) {
	-webkit-backdrop-filter: @backdrop-filter;
	        backdrop-filter: @backdrop-filter;
}
// ☠☠☠☠ DEPRECATED ☠☠☠☠
// // No longer necessary, but keeping around as a mixin for a while for compatability's sake
// .border-radius (@radius: 10px) {
// 	        border-radius: @radius;
// }
// end deprecated code ☠☠☠☠
.background-clip (@bgclip: padding-box) {
	-webkit-background-clip: @bgclip;
	        background-clip: @bgclip;
}

.transition (@transition: all 120ms ease-out) {
	-webkit-transition: @transition;
	        transition: @transition;
	// -webkit-font-smoothing: antialiased;
}
.transition-delay (@delay) {
	-webkit-transition-delay: @delay;
	        transition-delay: @delay;
}

.transform (@transformval) {
	-webkit-transform: @transformval;
	        transform: @transformval;
}
.transform-origin (@transformval) {
	-webkit-transform-origin: @transformval;
	        transform-origin: @transformval;
}

.perspective-origin (@perspectiveval) {
	-webkit-perspective-origin: @perspectiveval;
	    -ms-perspective-origin: @perspectiveval;
	        perspective-origin: @perspectiveval;
}

// Animations
// --------------------------
.animation (@anim) {
	animation: @anim;
}
.animation-name (@the-name: none) {
	animation-name: @the-name;
}
.animation-duration (@the-duration: 0s) {
	animation-duration: @the-duration;
}
.animation-timing-function (@the-timing-function: ease) {
	animation-timing-function: @the-timing-function;
}
.animation-delay (@the-delay: 0s) {
	animation-delay: @the-delay;
}
.animation-iteration-count (@the-iteration-count: 1) {
	animation-iteration-count: @the-iteration-count;
}
.animation-direction (@the-direction: normal) {
	animation-direction: @the-direction;
}
.animation-fill-mode (@the-fill-mode: none) {
	animation-fill-mode: @the-fill-mode;
}
.animation-play-state (@the-play-state: running) {
	animation-play-state: @the-play-state;
}

// Flexbox
// --------------------------

// Flex wrappers _________
.display-flex () {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
.display-inline-flex () {
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
}
.flex-direction (@the-direction: row) {
	    -ms-flex-direction: @the-direction;
	-webkit-flex-direction: @the-direction;
	        flex-direction: @the-direction;
}
.flex-wrap (@the-wrap: wrap) {
	    -ms-flex-wrap: @the-wrap;
	-webkit-flex-wrap: @the-wrap;
	        flex-wrap: @the-wrap;
}
.flex-justify-content (@the-justify: center) {
	          -ms-flex-pack: @the-justify;
	-webkit-justify-content: @the-justify;
	        justify-content: @the-justify;
}
.flex-align-items (@the-alignment: center) {
	     -ms-flex-align: @the-alignment;
	-webkit-align-items: @the-alignment;
	        align-items: @the-alignment;
}
.flex-align-content (@content-alignment: stretch) {
	   -ms-flex-line-pack: @content-alignment;
	-webkit-align-content: @content-alignment;
	        align-content: @content-alignment;
}

// Flex items _________
.order (@the-order: 1) {
	-ms-flex-order: @the-order;
	 -webkit-order: @the-order;
	         order: @the-order;
}
.flex (@the-flex: 1) {
	    -ms-flex: @the-flex;
	-webkit-flex: @the-flex;
	        flex: @the-flex;
}
.flex-grow (@the-grow: 0) {
	-webkit-flex-grow: @the-grow;
	        flex-grow: @the-grow;
}
.flex-shrink (@the-shrink: 1) {
	-webkit-flex-shrink: @the-shrink;
	        flex-shrink: @the-shrink;
}
.flex-basis (@the-basis: auto) {
	-webkit-flex-basis: @the-basis;
	        flex-basis: @the-basis;
}
.flex-align-self (@the-alignment: auto) {
	-ms-flex-item-align: @the-alignment;
	 -webkit-align-self: @the-alignment;
	         align-self: @the-alignment;
}
// --------------------------
// end Flexbox mixins

.user-select (@select-value: none) {
	-webkit-user-select: @select-value;
	   -moz-user-select: @select-value;
	    -ms-user-select: @select-value;
	        user-select: @select-value;
}

// Calc mixin: https://gist.github.com/kbav/7891881
// usage example: ```.calc-width(~"100% - 95px");```
.calc-width (@expression) {
	width: -webkit-calc(@expression);
	width:         calc(@expression);
}
