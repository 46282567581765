//
// Site Elements - Element overrides & unique styles
// --------------------------------------------------


// Use this file for making custom treatments for HTML element tags, e.g. img, a, figure, h1-h6, etc.
h1 {
	font-family: 'Coda', sans-serif;
}
h2 {
	color:#084db2;
	font-size: 1.5em;
	font-weight: 500;
}
h3 {
	color: #084db2;
	font-size: 1em;
	font-weight: 700;
}

p {
	font-size: .9em;
	line-height: 1.7em;
	font-weight: 300;
}

ul,
ol {
	margin: 0;
	padding: 0 0 0 1em;
}
li {
	font-size: .8em;
	font-weight: 300;
	line-height: 1.7em;
}


.input-field {
	font-style: italic;
}
