//
// Hamburger Menu Icon
// --------------------------------------------------

// Variables
// ---------------------------------------
@hamburger-bar-top: 				0;
@hamburger-bar-right: 				0;
@hamburger-bar-left: 				auto;
@hamburger-bar-width: 				25px;
@hamburger-bar-height: 				4px;
@hamburger-bar-gutter: 				(@hamburger-bar-height + @hamburger-bar-height + 1);
@hamburger-bar-color: 				#FFF;

@hamburger-button-width: 			(@hamburger-bar-width + @hamburger-button-gutter + @hamburger-button-gutter);
@hamburger-button-gutter: 			10px;
@hamburger-button-padding: 			0 @hamburger-button-gutter;
@hamburger-button-height: 			@header-body-height;
@hamburger-button-border: 			0;
@hamburger-button-border-radius: 	0;

@hamburger-button-highlight-bg-color: 	@ui-shade-bg-color;

@hamburger-button-display-none-vw: 	@desktop-styles-min;
// ---------------------------------------

// Styles
// ---------------------------------------
.hamburger-menu-button {
	.user-select (none);

	display: block;

	position: absolute;
	top: @hamburger-bar-top;
	right: @hamburger-bar-right;
	left: @hamburger-bar-left;
	z-index: 101; // Layer on top of .full-page-backdrop when search is brought up so it's still available for click

	width: @hamburger-button-width;
	height: @hamburger-button-height;
	padding: @hamburger-button-padding;
	border: @hamburger-button-border;
	border-radius: @hamburger-button-border-radius;

	background: transparent;
	text-decoration: none !important;

	// Icon children
	// --------------------------
	.bar {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin: auto;

		width: @hamburger-bar-width;
		height: @hamburger-bar-height;

		opacity: 1;
		background: @hamburger-bar-color;

		.transform (rotate(0deg));
		.transition (all .190s ease-in-out);
	}
	// positional adjustments
	.bar-1 {
		margin-top: -(@hamburger-bar-gutter);
	}
	// .bar-2,
	// .bar-3 {
	// 	// these *two* stay in the middle for default state
	// }
	.bar-4 {
		margin-top: @hamburger-bar-gutter;
	}

	// States
	// --------------------------
	&:focus,
	&:hover {
		background: @hamburger-button-highlight-bg-color;
	}
	&:hover {
		// .bar {
		// 	//
		// }
		.bar-1 {
			.transform (translateY(-2px));
		}
		.bar-4 {
			.transform (translateY(2px));
		}
	}
	&.is-active {
		.bar-1,
		.bar-4 {
			width: 0%;
			margin-top: 0;
			opacity: 0; // Fixes a Safari bug. Animation state doesn't seem to go fully through on first click/it retains focus
		}
		.bar-2 {
			.transform (rotate(45deg));
		}
		.bar-3 {
			.transform (rotate(-45deg));
		}
	}


	// Width at which to no longer display the hamburger button
	// --------------------------
	@media (min-width: @hamburger-button-display-none-vw) {
		display: none !important;
	}
}
