@import "variables";

@font-face {
  font-family: 'perch-icons';
  src:
    url('@{icomoon-font-path}/perch-icons.ttf?el2vn9') format('truetype'),
    url('@{icomoon-font-path}/perch-icons.woff?el2vn9') format('woff'),
    url('@{icomoon-font-path}/perch-icons.svg?el2vn9#perch-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'perch-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark {
  &:before {
    content: @icon-checkmark;
  }
}
.icon-filter {
  &:before {
    content: @icon-filter;
  }
}
.icon-google-plus {
  &:before {
    content: @icon-google-plus;
  }
}
.icon-google-plus-alt {
  &:before {
    content: @icon-google-plus-alt;
  }
}
.icon-pinterest {
  &:before {
    content: @icon-pinterest;
  }
}
.icon-pinterest-alt {
  &:before {
    content: @icon-pinterest-alt;
  }
}
.icon-youtube {
  &:before {
    content: @icon-youtube;
  }
}
.icon-mail {
  &:before {
    content: @icon-mail;
  }
}
.icon-twitter {
  &:before {
    content: @icon-twitter;
  }
}
.icon-twitter-alt {
  &:before {
    content: @icon-twitter-alt;
  }
}
.icon-share {
  &:before {
    content: @icon-share;
  }
}
.icon-file-pdf {
  &:before {
    content: @icon-file-pdf;
  }
}
.icon-warning-stop {
  &:before {
    content: @icon-warning-stop;
  }
}
.icon-exit {
  &:before {
    content: @icon-exit;
  }
}
.icon-info {
  &:before {
    content: @icon-info;
  }
}
.icon-question {
  &:before {
    content: @icon-question;
  }
}
.icon-warning {
  &:before {
    content: @icon-warning;
  }
}
.icon-alert {
  &:before {
    content: @icon-alert;
  }
}
.icon-blocked {
  &:before {
    content: @icon-blocked;
  }
}
.icon-link {
  &:before {
    content: @icon-link;
  }
}
.icon-search {
  &:before {
    content: @icon-search;
  }
}
.icon-zoom-in {
  &:before {
    content: @icon-zoom-in;
  }
}
.icon-zoom-out {
  &:before {
    content: @icon-zoom-out;
  }
}
.icon-print {
  &:before {
    content: @icon-print;
  }
}
.icon-download {
  &:before {
    content: @icon-download;
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar;
  }
}
.icon-chevron-down {
  &:before {
    content: @icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: @icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: @icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: @icon-chevron-up;
  }
}
.icon-home {
  &:before {
    content: @icon-home;
  }
}
.icon-linkedin {
  &:before {
    content: @icon-linkedin;
  }
}
.icon-linkedin-alt {
  &:before {
    content: @icon-linkedin-alt;
  }
}
.icon-rarebird-mark {
  &:before {
    content: @icon-rarebird-mark;
  }
}
.icon-bubble {
  &:before {
    content: @icon-bubble;
  }
}
.icon-plus {
  &:before {
    content: @icon-plus;
  }
}
.icon-minus {
  &:before {
    content: @icon-minus;
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook;
  }
}
.icon-facebook2 {
  &:before {
    content: @icon-facebook2;
  }
}
.icon-instagram {
  &:before {
    content: @icon-instagram;
  }
}
.icon-phone {
  &:before {
    content: @icon-phone;
  }
}
.icon-location {
  &:before {
    content: @icon-location;
  }
}

