//
// Bootstrap Component Animations
// --------------------------------------------------


// Variables
// ---------------------------------------
@bootstrap-fade-transition: 					opacity 0.15s linear;
@bootstrap-collapsing-transition-duration: 		0.35s;
// ---------------------------------------


// Styles
// ---------------------------------------
.fade {
	opacity: 0;
	-webkit-transition: @bootstrap-fade-transition;
	transition: @bootstrap-fade-transition;
}
.fade.in {
	opacity: 1;
}
.collapse {
	display: none;
}
.collapse.in {
	display: block;
}
tr.collapse.in {
	display: table-row;
}
tbody.collapse.in {
	display: table-row-group;
}
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-property: height, visibility;
	   transition-property: height, visibility;
	-webkit-transition-duration: @bootstrap-collapsing-transition-duration;
	   transition-duration: @bootstrap-collapsing-transition-duration;
	-webkit-transition-timing-function: ease;
	   transition-timing-function: ease;
}
