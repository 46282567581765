//
// Corner Close button
// --------------------------------------------------


// Variables
// ---------------------------------------
@corner-close-dimension: 						1.4em;
@corner-close-style-change-clearance-width: 	@modal-dialog-clearance-width;
// ---------------------------------------


// Styles
// ---------------------------------------
.button-corner-close {

	.box-sizing (border-box);

	position: absolute;
	top:   0;
	right: 0;
	z-index: 2; //Place above so the whole button is clickable

	display: inline-block;
	height: @corner-close-dimension;
	line-height: (@corner-close-dimension * .85);
	width: @corner-close-dimension;
	padding: 0;
	border: 0;
	border-radius: 0 0 0 0.2em;

	background-color: fade(#000, 60%);
	text-align: center;
	text-decoration: none !important;
	color: #fff;
	font-family: @font-family-clean;
	font-size: 22px;
	font-weight: normal;

	box-shadow: 0 2px 3px fade(#000, 30%);

	&:focus,
	&:hover {
		background-color: #000;
		border-color: #fff;
	}

	// Override mobile-first styles so .button-corner-close resembles traditional lightbox close button
	// --------------------------
	@media (min-width: @corner-close-style-change-clearance-width) {
		top:   -.75em;
		right: -.70em;
		border: 2px solid fade(#fff, 85%);
		border-radius: 2em;
	}
}


// Extensions
// --------------------------
.button-corner-close--minimal {
	position: absolute;
	top: -.60em;
	right: 0;

	height: 1em;
	line-height: 1;
	width: 1em;
	border: 0;

	background: transparent;
	text-align: center;
	font-size: 3em;
	font-weight: normal;
	text-decoration: none !important;
}
