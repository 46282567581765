//
// Islands
// --------------------------------------------------


// Variables
// ---------------------------------------
@island-padding: 					@common-gutter;
@island-margin-bottom: 				@common-gutter;
@island-bg-color: 					transparent;

@island-highlighted-bg-color: 		#F3F3F3;
// ---------------------------------------


// Styles
// ---------------------------------------
.island {
	.clear_float ();
	padding: @island-padding;
	margin-bottom: @island-margin-bottom;

	background-color: @island-bg-color;

	// Prevent last item from visually increasing the gutter
	> :last-child {
		margin-bottom: 0;
	}

	// ☠☠☠☠ DEPRECATED ☠☠☠☠
	// Deprecated because:
	//  - way too specific. This case should be addressed in Perch Docs with a .column-break-out helper/utility or something

	// // Conditional parentage
	// .modal & {
	// 	margin-left:  -(@common-gutter);
	// 	margin-right: -(@common-gutter);
	// }
	// end deprecated code ☠☠☠☠
}

// Extensions
// --------------------------
.island--highlighted {
	background-color: @island-highlighted-bg-color;
}
