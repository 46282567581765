//
// Forms
// --------------------------------------------------


// Variables
// --------------------------------------------------
@input-padding: 				0.5em 0.5em 0.4em;
@input-border-width: 			1px;
@input-border-color: 			@perch-color-gray;
@input-border-focus: 			@focus-highlight-color;
@input-border-radius: 			@common-border-radius;
@input-bg: 						#FAFAFA;
@input-font-family: 			@font-family-sans;
@input-font-size: 				16px; // 16px prevents mobile browsers from zooming in when selected
@input-line-height: 			1;
@input-color: 					@global-text-color;
@input-color-placeholder:		#808080;
@input-transition: 				background-color 120ms ease-out,
								box-shadow 120ms ease-out,
								border-color 120ms ease-out;
@input-box-shadow: 				inset 0 .2em .5em rgba(0,0,0,.13);

@form-column-margin-bottom: 	@common-vertical-gutter;

// States _________
@input-bg-disabled: 			@perch-color-gray;
@input-bg--highlighted: 		#FFF;
@input-box-shadow--hovered: 	0 0 .5em .2em rgba(122,122,122,.20);
@input-box-shadow--focused: 	inset 0 .2em .5em fade(#000, 9%),
								0 0 .5em .1em fade(@focus-highlight-color, 75%);

@input-textarea-padding: 		@input-padding;
@input-textarea-line-height: 	1.335;
// --------------------------------------------------


// Styles
// --------------------------------------------------


// Form Layout & Helper Classes
// ---------------------------------------
.form-column-group {
	.clear_float();

	// Force block formatting context
	padding-bottom: 1px;

	margin-bottom: @form-column-margin-bottom;
}

.form-column {
	margin-bottom: @form-column-margin-bottom;
}

// Unused _________
// .form-row {}
// .form-field-block {}


// Form Elements
// ---------------------------------------
fieldset {
	padding: 0;
	border: 0;
	margin: 0;
}

legend {
	padding-bottom: 4px;
}

label {
	display: block;
}

// Input styling
// --------------------------
.input-field {
	.box-sizing (border-box);

	display: inline-block;
	padding: @input-padding;
	width: 100%;
	max-width: 100%;
	border: @input-border-width solid @input-border-color;
	border-radius: @input-border-radius;

	vertical-align: middle;
	background-color: @input-bg;
	font-family: @input-font-family;
	font-size: @input-font-size; // == 16px --> this prevents mobile browsers from zooming in to the input field when it is selected
	line-height: @input-line-height;
	color: @input-color;

	.box-shadow (@input-box-shadow);

	.transition (@input-transition);

	// States _________
	&:hover {
		background-color: @input-bg--highlighted;
		border-color: lighten(@input-border-color, 05%);

		.box-shadow (@input-box-shadow--hovered);
	}
	&:focus {
		background-color: @input-bg--highlighted;
		border-color: @focus-highlight-color;

		.box-shadow (@input-box-shadow--focused);
	}
	&[disabled] {
		background-color: @input-bg;
		border-color: @input-border-color;

		.box-shadow (@input-box-shadow);
	}

	// Style overrides for textarea _________
	textarea& {
		padding: @input-textarea-padding;
		margin-right: 0; // Not sure what this is for... -- March 31, 2016 KB

		line-height: @input-textarea-line-height;
	}

	// Since we duplicate & enhance the effect with box-shadows, remove the default browser outline
	outline: 0;

}

.input-autowidth {
	width: auto;
}



// Input placeholder coloring
// ---------------------------------------
::-webkit-input-placeholder 	{ color: @input-color-placeholder !important; }
::-moz-placeholder          	{ color: @input-color-placeholder !important; }
:-ms-input-placeholder      	{ color: @input-color-placeholder !important; }
:placeholder-shown          	{ color: @input-color-placeholder !important; }



// Radio & Checkbox layout help
// ---------------------------------------
// * apply classes to the <fieldset>
// * useful for CC radios, etc.
// ---------------------------------------
.horizontal-inputs {
	label {
		display: inline-block;
		margin-right: 0.5em;
		vertical-align: middle;

		.label-contents {
			display: inline-block;
			vertical-align: middle;
		}
	}
}



// Input note
// ---------------------------------------
// * e.g. helper text beneath input field
// ---------------------------------------
.input-note {
	margin: .5em 0 0;
	font-size: .8em;
	font-style: italic;
	color: #777;
}



// Required symbol
// ---------------------------------------
// * i.e. an * (asterisk) character
// ---------------------------------------
.required-symbol {
	position: relative;
	top: 0.25em;
	vertical-align: baseline;

	color: @feedback-error-color;
	font-size: 1.5em;
	line-height: 0;
}
