//
// Layout - Main (<main> element & main containers)
// --------------------------------------------------


// Variables
// ---------------------------------------
@global-main-desktop-margin-top: 				@header-desktop-height;
@global-main-bg-color: 							transparent;
// ---------------------------------------


// Styles
// ---------------------------------------
.global-main {
	position: relative;
	// z-index: 1;

	padding-top: @header-height; // compensate for the fixed navigation layered on top of it
	padding-bottom: 0.1px; // Prevents last item's margin from collapsing, allowing this element's background-color to be honored. Another (more drastic) solution is to set `overflow: hidden;`.

	background-color: @global-main-bg-color;

	@media (min-width: @desktop-styles-min) {
		padding-top: @global-main-desktop-margin-top;
	}
}

.main-content-container {
	.common-content-vertical-spacing; // » see in Utilities.less
}
