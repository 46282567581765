//
// Modal plugin default styles
// --------------------------------------------------
// Heavily modified from the Bootstrap 3 modal plugin
// --------------------------------------------------


// Variables
// ---------------------------------------
@modal-backdrop-bg-color: 		@ui-overlay-shade-bg-color;

@modal-common-padding: 			15px;
@modal-fade-translate-start: 	-25%;
@modal-fade-transition-speed: 	0.3s;

@modal-dialog-max-width: 		700px;
@modal-dialog-margin: 			10px;
@modal-dialog-clearance-width: 	(@modal-dialog-max-width + 30);

@modal-content-border: 			1px solid fade(#000, 20%);
@modal-content-border-radius: 	@common-border-radius;
@modal-content-bg-color: 		#FFF;
@modal-content-box-shadow: 		0 5px 15px fade(#000, 50%);

@modal-header-padding: 			@modal-common-padding;
@modal-header-border: 			1px solid @line-divider-color;

@modal-body-padding: 			@modal-common-padding;

@modal-footer-padding: 			@modal-common-padding;
@modal-footer-border: 			@modal-header-border;
// ---------------------------------------


// Styles
// ---------------------------------------
.modal-open {
	overflow: hidden;
}
.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	-webkit-overflow-scrolling: touch;
	outline: 0;

	// Indicate clicking outside .modal-dialog closes the modal by changing the cursor
	cursor: auto;
	cursor: pointer;
	cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 512 512' fill='#FFFFFF' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E %3Cpath d='M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z'/%3E %3C/svg%3E") 15 15, pointer;

	// States _________
	&.fade .modal-dialog {
		.transform(translate(0, @modal-fade-translate-start));
		.transition(transform @modal-fade-transition-speed ease-out);
	}
	&.in .modal-dialog {
		.transform(translate(0, 0));
	}
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}
.modal-dialog {
	position: relative;
	width: auto;
	max-width: @modal-dialog-max-width;
	margin: @modal-dialog-margin auto;

	// Reset the cursor for when mouse in .modal-dialog
	cursor: default;
}

// Modal Content
// --------------------------
.modal-content {
	position: relative;
	border: @modal-content-border;
	border-radius: @modal-content-border-radius;
	margin: auto @modal-dialog-margin;

	background-color: @modal-content-bg-color;
	box-shadow: @modal-content-box-shadow;
	background-clip: padding-box;
	outline: 0;
}

// Modal Backdrop
// --------------------------
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: @modal-backdrop-bg-color;

	// States _________
	&.fade { opacity: 0; }
	&.in   { opacity: 1; }
}

// Modal Header
// --------------------------
.modal-header {
	padding: @modal-header-padding;
	border-bottom: @modal-header-border;
	// min-height: 16.428571429px;
}
.modal-header .close {
	margin-top: -2px;
}
.modal-title {
	margin: 0;
	line-height: 1.42857143;
}

// Modal Body
// --------------------------
.modal-body {
	position: relative;
	// padding: 20px;
	padding: @modal-body-padding;
}

// Modal Footer
// --------------------------
.modal-footer {
	// margin-top: 15px;
	padding: @modal-footer-padding;
	text-align: right;
	border-top: @modal-footer-border;
}

.modal-footer .button + .button {
	margin-left: 5px;
}

// Modal Scrollbar Measure
// --------------------------
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

// Clearfixin'
// --------------------------
.modal-header::before,
.modal-header::after,
.modal-footer::before,
.modal-footer::after {
	content: " ";
	display: table;
}
.modal-header::after,
.modal-footer::after {
	clear: both;
}


// Media Queries
// ---------------------------------------
@media screen and (min-width: @modal-dialog-clearance-width) {
	.modal-dialog {
		// left: auto;
		// right: auto;
		// width: 600px;
		padding-top: 30px;
		padding-bottom: 30px;
	}
}


// Assistive Utilities
// ---------------------------------------
// Kill the scroll on the body
// .modal-open {
// 	overflow: hidden;
// }
