//
// Full Page Backdrop
// --------------------------------------------------

// Variables
// ---------------------------------------
@full-page-backdrop-z-index: 				100;
@full-page-backdrop-bg-color: 				@ui-overlay-shade-bg-color;
@full-page-backdrop-animation-in: 			fadeIn .25s ease;
@full-page-backdrop-animation-out: 			fadeOut .25s ease 0s 1 normal forwards;
// ---------------------------------------


// Styles
// ---------------------------------------
.full-page-backdrop {
	display: none;

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: @full-page-backdrop-z-index;

	min-height: 2000px;
	min-height: 100vh;

	background-color: @full-page-backdrop-bg-color;

	.animation (@full-page-backdrop-animation-in);

	&.is-active {
		display: block;
	}

	&.animating-out {
		.animation (@full-page-backdrop-animation-out);
	}
}
