//
// Buttons
// --------------------------------------------------


// Core Button class & extensions
// ---------------------------------------

// Variables
// --------------------------

// Default button _________
@button-height: 					2em;
@button-line-height: 				(@button-height * .95);
@button-lesser-height: 				2.0em;
@button-tiny-height: 				1.75em;

@button-border-width: 				1px;
@button-border-color: 				@button-color;
@button-border-radius: 				0.4em;

@button-padding: 					0 1.0em;

@button-font-family: 				@font-family-base;
@button-font-size:					@font-size-base;

@button-color:						@link-color;
@button-highlight:					lighten(@button-color, 10%);
@button-shade:						darken(@button-color, 20%);

@button-font-weight: 				normal;
@button-text-transform: 			none;
@button-text-color: 				#FFFFFF;
@button-text-shadow: 				0 0.0825em 0.0825em fade(#000, 25%);

@button-drop-shadow-color: 			fade(#000, 25%);
@button-box-shadow:					inset 0 -0.3em 0.5em 0 rgba(0, 0, 0, 0.15),
									inset 0  0.3em 0.5em 0 rgba(255, 255, 255, 0.10),
									      0 .075em 0.5em 0 @button-drop-shadow-color;

// Interaction
@button-transition: 				all .12s ease-out;
@button-tap-highlight-color: 		fade(#000, 0%);

// States
@button-highlight-text-color: 		#FFFFFF;
@button-shade-text-color: 			#CCCCCC;
@button-shade-text-shadow: 			0 -0.0625em 0.0625em fade(#000, 50%);
@button-active-transform: 			translateY(1px);
@button-active-box-shadow: 			inset 0 -0.125em 0.375em 0 rgba(0,0,0,.01),
									inset 0 0.1875em 0.125em 0 rgba(0,0,0,.2),
									0 0 0 0 rgba(0,0,0,.15);

// Extensions _________

// Size extensions
@smaller-button-size:				@button-font-size / 1.20;	// ~ 15px @ base 18px
@smallest-button-size:				@button-font-size / 1.50;	// ~ 12px @ base 18px
@larger-button-size:				@button-font-size * 1.2222;	// ~ 22px @ base 18px
@largest-button-size:				@button-font-size * 1.4444;	// ~ 26px @ base 18px

// Layout extensions
@button-multiline-min-width: 		11.5em;
@button-multiline-line-height: 		1.25;
@button-multiline-padding: 			.75em 1.5em;

// Colored extensions

// Secondary
@button-secondary-color: 			@link-secondary-color;
@button-secondary-highlight:		lighten(@button-secondary-color, 10%);
@button-secondary-shade:			darken(@button-secondary-color, 15%);
@button-secondary-text-color:		#FFF;
@button-secondary-text-shadow: 		@button-text-shadow;
@button-secondary-box-shadow: 		@button-box-shadow;

// Tertiary
@button-tertiary-color: 			@link-tertiary-color;
@button-tertiary-highlight:			lighten(@button-tertiary-color, 10%);
@button-tertiary-shade:				darken(@button-tertiary-color, 15%);
@button-tertiary-text-color:		#FFF;
@button-tertiary-text-shadow: 		@button-text-shadow;
@button-tertiary-box-shadow: 		@button-box-shadow;

// Subtle
@button-subtle-color: 				#F3F3F3;
@button-subtle-highlight:			lighten(@button-subtle-color, 10%);
@button-subtle-shade:				darken(@button-subtle-color, 30%);
@button-subtle-border-color: 		@button-subtle-shade;
@button-subtle-text-color: 			#333;
@button-subtle-text-shadow: 		0 0.0825em 0.0825em rgba(255,255,255,.75);
@button-subtle-box-shadow: 			inset 0 -0.125em 0.375em 0 rgba(0,0,0,.10),
									inset 0 0.125em 0.375em rgba(255,255,255,.25),
									0 0.0625em 0.1875em 0 rgba(0,0,0,.10);
@button-subtle-border-highlight:	lighten(#000, 60%);
@button-subtle-active-text-shadow: 	0 -0.0625em 0.0625em rgba(255,255,255,.75);

// Functional extensions
@button-arrowed-character: 			"\25b6";
@button-arrowed-character-size:		.6em;
@button-arrowed-left-padding: 		.5em;
@button-arrowed-vertical-align: 	baseline;

// Outlined extension
@button-outline-color:				@button-color;
@button-outline-secondary-color:	@button-secondary-color;
@button-outline-tertiary-color:		@button-tertiary-color;
@button-outline-subtle-color:		#7F7F7F;
//
@button-outline-highlight:			@button-highlight;
@button-outline-secondary-highlight:@button-secondary-highlight;
@button-outline-tertiary-highlight:	@button-tertiary-highlight;
@button-outline-subtle-highlight:	lighten(@button-outline-subtle-color, 10%);

@button-outline-border-width: 		0.10em;
@button-outline-active-bg-color: 	fade(#000, 2.5%);
@button-outline-text-shadow: 		0 0.0625em 0.1875em rgba(0, 0, 0, 0.05);
@button-outline-active-text-shadow: none;
@button-outline-box-shadow: 		inset 0 0.0625em 0.1875em 0 rgba(0,0,0,.15),
									      0 0.0625em 0.1875em 0 rgba(0,0,0,.15);

// Colorized shadows _________
// If set to true, the drop shadows will be colorized instead of referring to the default drop shadow color.
@setting--button-colorized-shadows: 		false;

@button-colorized-shadow-opacity: 			70%;
@button-secondary-colorized-shadow-opacity: 70%;
@button-tertiary-colorized-shadow-opacity: 	70%;
// --------------------------

// Styles
// --------------------------
.button {
	position: relative;
	display: inline-block;
	padding: @button-padding;
	height: @button-height;
	line-height: @button-line-height; // Helps with appearance of vertically centered text
	border: @button-border-width solid @button-border-color;
	border-radius: @button-border-radius;

	vertical-align: middle;
	text-align: center;
	font-family: @button-font-family;
	font-size: @button-font-size;
	font-weight: @button-font-weight;
	text-transform: @button-text-transform;
	.font-smoothing ();
	color: @button-text-color;
	text-shadow: @button-text-shadow;
	text-decoration: none !important;
	background-color: @button-color;

	cursor: pointer;

	// Removes the highlight that appears when tapping
	-webkit-tap-highlight-color: @button-tap-highlight-color;

	// Removes double-tap-to-zoom functionality on touch IE browsers, effectively removing tap delay
	-ms-touch-action: none;

	white-space: nowrap;

	& when (@setting--button-colorized-shadows = false) {
		.box-shadow (@button-box-shadow);
	}
	& when (@setting--button-colorized-shadows = true) {
		@button-drop-shadow-color: fade(@button-color, @button-colorized-shadow-opacity);
		.box-shadow (@button-box-shadow);
	}

	.transition (@button-transition);

	// states
	// --------------------------
	// &:visited {
	// 	color: #fff;
	// }
	&:focus,
	&:hover {
		background-color: @button-highlight;
		color: @button-highlight-text-color;
	}
	&:active,
	&.is-active {
		color: @button-shade-text-color;
		text-shadow: @button-shade-text-shadow;
		background-color: @button-shade;
		// remove outline on click
		outline: none;

		.transform (@button-active-transform);
		.box-shadow (@button-active-box-shadow);
	}

}

// size & layout extensions
// --------------------------

// Sizing _________
.button--smaller {
	font-size: @smaller-button-size;
}
.button--smallest {
	font-size: @smallest-button-size;
}
.button--larger {
	font-size: @larger-button-size;
}
.button--largest {
	font-size: @largest-button-size;
}

// Layout _________
.button--multiline {
	min-width: @button-multiline-min-width;
	max-width: 100%;
	height: auto;
	line-height: @button-multiline-line-height;
	padding: @button-multiline-padding;

	text-align: left;
	white-space: normal;
}
.button--fullwidth {
	width: 100%;
}

// color extensions
// --------------------------
.button--secondary {
	border-color: @button-secondary-color;
	background-color: @button-secondary-color;

	color: @button-secondary-text-color;
	text-shadow: @button-secondary-text-shadow;

	& when (@setting--button-colorized-shadows = false) {
		.box-shadow (@button-secondary-box-shadow);
	}
	& when (@setting--button-colorized-shadows = true) {
		@button-drop-shadow-color: fade(@button-secondary-color, @button-secondary-colorized-shadow-opacity);
		.box-shadow (@button-secondary-box-shadow);
	}

	&:focus,
	&:hover {
		background-color: @button-secondary-highlight;

		color: @button-secondary-text-color;
	}
	&:active {
		background-color: @button-secondary-shade;
	}
}
.button--tertiary {
	border-color: @button-tertiary-color;
	background-color: @button-tertiary-color;

	color: @button-tertiary-text-color;
	text-shadow: @button-tertiary-text-shadow;

	& when (@setting--button-colorized-shadows = false) {
		.box-shadow (@button-tertiary-box-shadow);
	}
	& when (@setting--button-colorized-shadows = true) {
		@button-drop-shadow-color: fade(@button-tertiary-color, @button-tertiary-colorized-shadow-opacity);
		.box-shadow (@button-tertiary-box-shadow);
	}

	&:focus,
	&:hover {
		background-color: @button-tertiary-highlight;

		color: @button-tertiary-text-color;
	}
	&:active {
		background-color: @button-tertiary-shade;
	}
}

.button--subtle {
	border-color: @button-subtle-border-color;
	background-color: @button-subtle-color;

	color: @button-subtle-text-color;
	text-shadow: @button-subtle-text-shadow;

	.box-shadow (@button-subtle-box-shadow);

	&:focus,
	&:hover {
		border-color: @button-subtle-border-highlight;

		background-color: @button-subtle-highlight;
		color: @button-subtle-text-color;
	}
	&:active {
		background-color: @button-subtle-shade;
		text-shadow: @button-subtle-active-text-shadow;
	}
}
	// ☠☠☠☠ DEPRECATED ☠☠☠☠
	// Deprecated because:
	//  - too specific for Perch. a nice candidate for an on-project extension
	.button--subtle--negative {
		border-color: @feedback-negative-color !important;
	}
	.button--subtle--positive {
		border-color: @feedback-positive-color !important;
	}
	// end deprecated code ☠☠☠☠

// outlined buttons
// --------------------------
.button--outline {
	border: @button-outline-border-width solid currentColor;

	background: transparent;
	text-shadow: @button-outline-text-shadow;
	color: @button-outline-color;
	.box-shadow (@button-outline-box-shadow);

	&.button--secondary { color: @button-outline-secondary-color; }
	&.button--tertiary  { color: @button-outline-tertiary-color; }
	&.button--subtle    { color: @button-outline-subtle-color; }

	&:focus,
	&:hover {
		background: transparent;
		color: @button-outline-highlight;

		&.button--secondary { color: @button-outline-secondary-highlight; }
		&.button--tertiary  { color: @button-outline-tertiary-highlight; }
		&.button--subtle    { color: @button-outline-subtle-highlight; }
	}
	&:active {
		background-color: @button-outline-active-bg-color;
		text-shadow: @button-outline-active-text-shadow;
	}
}

// generated trailing arrow
// --------------------------
.button--arrowed {
	.hardware-accelerate ();

	&::after {
		content: @button-arrowed-character;
		display: inline-block;
		padding-left: @button-arrowed-left-padding;
		vertical-align: @button-arrowed-vertical-align;

		font-family: "Hiragino Kaku Gothic ProN", sans-serif; // fixes iOS showing an emoji arrow by declaring a font that the device has, that includes the unicode glyph
		font-size: @button-arrowed-character-size;

		.transition(all .12s ease-out);
	}
	&:hover::after {
		.transform(translateX(0.2em));
	}
}

// Colorized shadows
// --------------------------
.button--colorized-shadow {
	@button-drop-shadow-color: fade(@button-color, @button-colorized-shadow-opacity);
	box-shadow: @button-box-shadow;
}
.button--secondary--colorized-shadow {
	@button-drop-shadow-color: fade(@button-secondary-color, @button-secondary-colorized-shadow-opacity);
	box-shadow: @button-secondary-box-shadow;
}
.button--tertiary--colorized-shadow {
	@button-drop-shadow-color: fade(@button-tertiary-color, @button-tertiary-colorized-shadow-opacity);
	box-shadow: @button-tertiary-box-shadow;
}

// flat / removed box-shadows
// --------------------------
.button--flat {
	text-shadow: none;
	.box-shadow (none);
}
