//
// Wrapping Labels
// --------------------------------------------------


// Variables
// --------------------------------------------------
// Defaults & Layout _________

// Outer label
@wrapping-label-margin-bottom: 				0.5em;
@wrapping-label-font-size: 					0.9em;
@wrapping-label-font-weight: 				normal;
@wrapping-label-transition: 				all 0.1s ease-out;

// Input (radio or checkbox)
@wrapping-label-input-left: 				4px;

// Label contents / inner label
@wrapping-label-contents-padding: 			.1em .2em .1em 2em;
@wrapping-label-contents-transition: 		all .12s ease-out;

// States _________

// Focus & Hover
@wrapping-label-hover-bg-color: 			fade(#000, 04%);

// Active
@wrapping-label-active-bg-color: 			fade(#000, 8%);

// Checked
@wrapping-label-checked-bg-color: 			fade(@focus-highlight-color, 15%);
@wrapping-label-checked-text-shadow: 		0 0.0625em 0.0625em #fff;

// Checked Focus & Hover
@wrapping-label-checked-focus-bg-color: 	fade(@focus-highlight-color, 10%);
// --------------------------------------------------


// Styles
// --------------------------------------------------

// Base Wrapping Label
// ---------------------------------------
.wrapping-label {
	position: relative;
	margin-bottom: @wrapping-label-margin-bottom;

	font-size: @wrapping-label-font-size;
	font-weight: @wrapping-label-font-weight;

	.transition (@wrapping-label-transition);

	// Children _________
	input {
		position: absolute;
		left: @wrapping-label-input-left;

		// Vertically center these bad boys using CSS translate trick
		top: 50%;
		.transform(translateY(-50%));

		display: inline-block;
		vertical-align: middle;

		font-size: 16px;
	}
	.label-contents {
		display: block;
		padding: @wrapping-label-contents-padding;

		.transition (@wrapping-label-contents-transition);
	}

	// States _________
	input:focus ~ .label-contents,
	&:hover .label-contents {
		background-color: @wrapping-label-hover-bg-color;
	}
	&:active {
		background-color: @wrapping-label-active-bg-color;
	}
	input:checked ~ .label-contents {
		background-color: @wrapping-label-checked-bg-color;
		text-shadow: @wrapping-label-checked-text-shadow;
	}
	&:hover input:checked ~ .label-contents,
	input:checked:focus ~ .label-contents {
		background-color: @wrapping-label-checked-focus-bg-color;
	}
}
