//
// Global Logo
// --------------------------------------------------

// Variables
// ---------------------------------------
@global-logo-position: 				absolute;
@global-logo-top: 					10px;
@global-logo-right: 				auto;
@global-logo-left: 					10px;
@global-logo-z-index: 				11;
@global-logo-width: 				150px;
@global-logo-padding: 				0;
@global-logo-margin: 				0;

// Desktop
@global-logo-desktop-position: 		@global-logo-position; // absolute;
@global-logo-desktop-top: 			@global-logo-top; // 10px;
@global-logo-desktop-right: 		@global-logo-right; // auto;
@global-logo-desktop-left: 			@common-gutter; // 10px;
@global-logo-desktop-z-index: 		@global-logo-z-index; // 11;
@global-logo-desktop-width: 		200px; // 150px;
@global-logo-desktop-padding: 		@global-logo-padding; // 0;
@global-logo-desktop-margin: 		@global-logo-margin; // 0;
// ---------------------------------------


// Styles
// ---------------------------------------
.global-logo {
	display: block;
	position: @global-logo-position;
	top: @global-logo-top;
	right: @global-logo-right;
	left: @global-logo-left;
	z-index: @global-logo-z-index;

	width: @global-logo-width;
	padding: @global-logo-padding;
	margin: @global-logo-margin;

	line-height: 1;
}

.global-logo-image {
	max-width: 100%;
	height: auto;
}


// Desktop media query
// ---------------------------------------
@media (min-width: @desktop-styles-min) {
	.global-logo {
		position: @global-logo-desktop-position;
		top: @global-logo-desktop-top;
		right: @global-logo-desktop-right;
		left: @global-logo-desktop-left;
		z-index: @global-logo-desktop-z-index;

		width: @global-logo-desktop-width;
		padding: @global-logo-desktop-padding;
		margin: @global-logo-desktop-margin;
	}
}
