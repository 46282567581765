//
// Global Search Overlay
// --------------------------------------------------

// Variables
// ---------------------------------------
@global-search-overlay-z-index: 			100;
@global-search-overlay-bg-color: 			@ui-overlay-shade-bg-color;
@global-search-overlay-top-gutter: 			2em;

@global-search-overlay-inner-animation-in: 	fadeInUp .4s ease;

@global-search-overlay-input-width: 		14em;
@global-search-overlay-input-padding: 		@input-padding;

@global-search-overlay-elements-size: 		4vw;
@global-search-overlay-elements-lg-size: 	39px;

@global-search-overlay-closer-font-size: 	12px;
@global-search-overlay-closer-md-font-size:	1em;
@global-search-overlay-closer-lg-font-size:	1.2em;
@global-search-overlay-closer-color: 		#EFEFEF;
@global-search-overlay-closer-highlight: 	#FFFFFF;
// ---------------------------------------

// Styles
// ---------------------------------------
.global-search-overlay {
	z-index: @global-search-overlay-z-index;

	// Calculate header height with some added space
	padding-top: 200px;
	padding-top: -webkit-calc(~"@{header-height} + @{global-search-overlay-top-gutter}");
	padding-top: -moz-calc(~"@{header-height} + @{global-search-overlay-top-gutter}");
	padding-top: calc(~"@{header-height} + @{global-search-overlay-top-gutter}");

	text-align: center;
	background-color: @global-search-overlay-bg-color;

	// Have cursor turn to an "X" to indicate ability to click to close
	// Reset said cursor for inner elements
	cursor: auto;
	cursor: pointer;
	cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 512 512' fill='#FFFFFF' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E %3Cpath d='M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z'/%3E %3C/svg%3E") 15 15, pointer;
}

.global-search-overlay__inner {
	.animation(@global-search-overlay-inner-animation-in);

	// Reset the parent cursor rule for when mouse inside this element
	cursor: default;
}

.global-search-overlay-input-wrapper {
	.display-flex();
	.flex-justify-content(center);
	margin-bottom: @common-vertical-gutter;
}

// Font sizes set to 16px to prevent Mobile Safari from auto-zooming in when the field is focused
.global-search-overlay__input,
.global-search-overlay__submit {
	font-size: 16px;
}

.global-search-overlay__input {
	vertical-align: top;
	width: @global-search-overlay-input-width;
	height:      auto;
	line-height: 1;
	padding: @global-search-overlay-input-padding;
}

.global-search-overlay__submit {
	vertical-align: top;
	font-weight: normal;
	text-transform: none;

	// allow the flex parent to define its height
	height: auto;
	line-height: 1;
}

.global-search-overlay__close-button {
	font-size: @global-search-overlay-closer-font-size;
	color: @global-search-overlay-closer-color;

	&:focus,
	&:hover {
		color: @global-search-overlay-closer-highlight;
	}
}

// Media Queries
// --------------------------

// As the browser width grows, scale the size of the input & button
@media (min-width: @screen-md-min) {
	.global-search-overlay__input,
	.global-search-overlay__submit {
		font-size: @global-search-overlay-elements-size;
	}
	.global-search-overlay__close-button {
		font-size: @global-search-overlay-closer-md-font-size;
	}
}

// Cap the input & button sizes
@media (min-width: @screen-lg-min) {
	.global-search-overlay__input,
	.global-search-overlay__submit {
		font-size: @global-search-overlay-elements-lg-size;
	}
	.global-search-overlay__close-button {
		font-size: @global-search-overlay-closer-lg-font-size;
	}
}

@media (min-width: @desktop-styles-min) {
	.global-search-overlay {
		padding-top: -webkit-calc(~"@{header-desktop-height} + @{global-search-overlay-top-gutter}");
		padding-top: -moz-calc(~"@{header-desktop-height} + @{global-search-overlay-top-gutter}");
		padding-top: calc(~"@{header-desktop-height} + @{global-search-overlay-top-gutter}");
	}
}
