//
// Site Components - Component overrides & unique styles
// --------------------------------------------------

.flex-row {
	.display-flex();
	.flex-wrap(wrap);
	.flex-align-items(center);
}



.button {
	max-width: 350px;
	max-height: 90px;
	width: 95%;
}
.button:hover,
.button:focus {
	color: #ffffff;
}



.hero-block {
	padding-top: 5%;
	padding-bottom: 5%;

	background: #3c3a3a;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	color: #ffffff;
}

.hero-slider-block {
	padding-top: 50px;
	padding-bottom: 100px;
	margin-top: -138px;

	text-align: center;
	color: #ffffff;
	text-shadow: 0 0 1em rgba(0, 0, 0, 0.50);

	background: #3c3a3a url('../images/bg-hero-1.jpg') 50% 0% no-repeat;
	// background-color: #3c3a3a;
	// background-image: url('../images/bg-hero-1.jpg');
	// background-position: center top;
	// background-repeat: no-repeat;
	background-size: auto 100%;
	background-attachment: fixed;
	//background-blend-mode: darken;

	// blend mode optional at this stage; will be used more in the next demo.
	//transition: background-image 1s ease-in-out;
	transition: all 3s ease-in;
}

//.hero-slider-block:focus {
	//background-position:url(../images/bg-hero-2.jpg);
//}
.hero-slider-container {
	margin-top: 50%;
}
@media (min-width: @screen-lg-min) {
.hero-slider-container {
	margin-top: 25%;
}
}



.home-items-carousel-container {
	padding-left: 0;
	padding-right: 0;
}
.home-items-carousel {
	.flickity-prev-next-button {
		display: none;
	}

	&:focus-within,
	&:hover {
		.flickity-prev-next-button {
			display: block;
		}
	}

	&::after {content: "flickity"; display: none; }
}
// styles for when max width is reached
@media (min-width: @container-xxl) {
	.home-items-carousel {
		overflow: hidden; // float layout clearing
		display: inline-block;
		margin-left: auto;
		margin-right: auto;

		.carousel-cell {
			width: 200px;
		}

		&::after {
			// This disables flickity at these widths and above
			content: "";
		}
	}
}



.capabilities-cards-block {
	background-color: #131313 !important;
}
.capabilities-cards-row {
	margin-bottom: -1px; // fix for chrome subpixel rendering
}
.capability-card {
	overflow: hidden;
	// max-height: 552px;
	padding: 0;
	margin: 0;

	background: #efefef;

	// @media (max-width:769px) {
	// 	height: 225px;
	// }
}
.capability-card h4 {
	position: absolute;
	top:  15%;
	left: 15%;
	z-index: 4;

	border-top: 2px #1E5294 solid;
	padding: 15px 0;

	text-shadow: 0 0 1em rgba(0, 0, 0, 0.50);

	// Generated right arrow / double chevron
	&::after {
		content: "»";
		display: inline-block;
		padding-left: .25em;
		opacity: 0;
		transform: translateX(-100%);
		transition: all .35s ease;
		color: #FFF;
	}

	@media (min-width: @site-max-width) {
		font-size: 1.4em;
	}
}
.capability-card-link {
	display: block;

	// Child elements _________
	.overlay {
		position: absolute;
		left: 0;
		top: 0;

		width: 100%;
		height: 100%;

		background: rgba(0, 0, 0, 0.75);
		opacity: .35;
		transition: opacity 0.35s ease-in-out;
	}
	img {
		display: block;
		max-width: 100%;
		height: auto;
		transition: all 0.35s ease-in-out;
		transform: scale(1.01);
	}

	// States _________
	&:focus,
	&:hover {
		.overlay {
			opacity: 1;
		}
		img {
			transform: scale(1.06);
		}
		h4 {
			border-top-color: #FFF;
			&::after {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}

	// Mobile width adjustments for automatic image cropping
	@media (max-width: @screen-sm-max) {
		height: 0;
		padding-top: 150px;
		padding-top: calc(~"100px + 15vw");

		img {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		&:focus,
		&:hover {
			img {
				transform: scale(1.05) translateY(-50%);
			}
		}
	}
}

.card-two img {
	//transform: translateX(-40%);
	// margin-left: -300px;
}
.card-three img {
	//transform: translateX(-30%);
}
.card-one img {
	//transform: scale(1.1);
}

//temporary styling until can get slider working
// .img-responsive {
// 	float: left;
// }

.subhead-block {
	//padding-bottom: 1%;
	font-size: 18px;
	font-weight: 100;
}
.entry-title {
	//font-size: 3em;
	font-weight: 400;
}

// .top-content-block {}
@media (min-width: @screen-lg-min) {
.entry-title {
	font-size: 70px;
	font-weight: 400;
	}
	.subhead-block {
	font-size: 24px;
	font-weight: 100;
}
}

.main-content-row {
	margin: 0 auto;
}

// @media (min-width: 769px) {
// 	.main-content-column {
// 		padding-right: 5%;
// 	}
// }
// .main-content-column-photo {}


.equipment-row {
	margin-bottom: 40px;

	.column {
		float: none;
		display: inline-block;
		vertical-align: top;
		margin-right: -.25em;
	}
}

.content-photo {
	max-width: 550px;
	width: 100%;
	margin-top: 20px;
}

.caption {
	font-size: .8em;
	font-style: italic;
	font-family: serif;
	text-align: center;
	padding: 0  8%;
}

//For the about us page
.sepia {
	background-color: #e2ddd7;
}

//For capabilities
.gray-block {
	background-color: #ececec;

}
.triangle-down {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 20px solid #ececec;
	margin: 0 auto;
}

.gray-block-container {
	text-align: center;
}
.gray-block-row {
	padding: 5% 0;
}
.gray-block-content {
	max-height: 290px;
	max-width: 70%;
	margin: 0 auto;
}

.call-out-block {
	background-color: #1e52a4;
	color: #ffffff;
}
.call-out-container {
	// padding-top:    5%;
	// padding-bottom: 5%;
	.common-content-vertical-spacing;
}
.call-out-row {
	width: 100%;
	margin: 0 auto;
	.display-flex();
	.flex-wrap(wrap);
	.flex-justify-content(center);
	.flex-align-items(center);

	text-align: center;

	// rm BS3 generated float clearing element
	&::after {
		display: none;
	}
}
.call-out-col-text {
	.flex(1 1 auto);
	// max-width: 500px;
	width: 100%;
	margin-bottom: @common-gutter;

	> h2 {
		margin-bottom: 0;

		text-align: center !important;
		color: #ffffff;
		font-size: 1.6em;
		font-weight: 400;
		line-height: 1.35;
	}
}

// .call-out-button {}

@media (min-width: @screen-lg-min) {
	.call-out-row {
		.flex-justify-content(space-between);

		text-align: left;
	}
	.call-out-col-text {
		width: 50%;
		max-width: 500px;
		padding-left: 0;
		margin-bottom: 0;

		> h2 { text-align: left !important; }
	}
	// .call-out-button {}
}

// @media (max-width: 768px) {
// 	.call-out-col-text{
// 		text-align: center;
// 	}
// 	.call-out-col-button {
// 		line-height: 170px;
// 	}
// 	.call-out-container h2 {
// 		top:50px !important;
// 	}
// 	.map-link-block-content {
// 		width: 94%;
// 	}
// }

// .map-location-block-content {
// 	//height: 50vh;
// 	//background: #EFEFEF url(../images/bg-map-location.jpg) top;
// 	//background-size: cover;
// 	//max-height: 629px;
// }

.map-location-block-content {
	position: relative;
	min-height: 300px;
}

#map_container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#map {
	padding: 0;
	margin: 0;
	height: 100%;
	// width: 106%;
	// transform: translateX(-3%);
	//margin-bottom: -75px;
}
.contact-location-row {
	background-color: #EFEFEF;

	.display-flex();
	.flex-wrap(wrap);
	// .flex-align-items(center);
}

.contact-block-text {
	background-color: #1e52a4;
	color: #ffffff;
	padding: 5% 0;
}
.contact-block-text img {
	width: 90%;
	max-width: 415px;
	margin-bottom: 15px;
}

.map-icon-contact {
	font-size: 4em;
	position: relative;
	top: 129px;
	left: 62px;
	color: #52acf0;
	display: block;
	width: 100%;

	// MQs _________
	@media (min-width: 400px) and (max-width:425px) {
		font-size: 4em;
		position: relative;
		top: 129px;
		left: 115px;
		color: #52acf0;
		display: block;
		width: 100%;
	}
	@media (min-width: 426px) and (max-width:768px) {
		font-size: 4em;
		position: relative;
		top: 200px;
		left: 248px;
		color: #52acf0;
		display: block;
		width: 100%;
	}
	@media (min-width: 769px) {
		font-size: 4em;
		position: relative;
		top: 226px;
		left: 168px;
		color: #52acf0;
		display: block;
		width: 100%;
	}
}


//feature block
.feature-block-chrome {
	padding: 5% 0;

	background-image:url('../images/bg-nav-ss.jpg');
	background-position: 0% 50%;
}

// .feature-block-container {}

.feature-block-row {
	margin: 0 auto;
}

.feature-block-col-image {
	// padding-top: 1%;
	// padding-bottom: 4%;
	margin: 20px auto;

	> img {
		width: 500px;
		max-width: 100%;
		height: auto;
	}
}

.feature-block-col-text {
	// padding-top: 4%;
	// padding-left: 10%;
	// padding-bottom: 2%;
	margin: 20px auto;
}
.feature-block-col-text.capability {
	padding-left: 0;
	padding-top: 0;
}

//make image full width on mobile
@media (max-width: @screen-sm-max) {
	.full-width-img {
		width: 100vw;
		left: 50%;
		transform: translateX(-50%);
		position: relative;
		max-width: 800px;
	}
}

//
@media (max-width:768px) {
	h2 {
		text-align: center !important;
	}
}


//map link block
// .map-link-block {}
.map-link-block-content {
	z-index: 3000;
}
.map-link-block-container.container {
	max-width: 100% !important;
}
.map-link-block-container {
	background-image: url('../images/bg-map-us-warehouse.jpg');
	background-position: 70%;
	background-size: cover;
	color: #788585;
	text-decoration: none;
    transition: all 150ms ease-out;
    position: relative;
    display: block;
    overflow: hidden;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 1rem 10px;
    background-color: transparent;
    text-align: center;
    font-size: .9em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none!important;
    color: #fff!important;

	// Generated cover
	&::after {
	    content: " ";
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    z-index: 1;
	    width: 100%;
	    height: 100%;
	    background: linear-gradient(to bottom,rgba(33,33,33,0) 30%,rgba(33,33,33,.8) 100%);
	    transform: translateY(100%);
	    transition: all .2s ease-out;
	    pointer-events: none;
	}
	&:focus::after,
	&:hover::after {
	    transform: translateY(0);
	}
}
// .map-link-block-row {}
.map-link-block-content {
	text-align: center;
	padding-top: 8%;
}
.map-link-block-content h3 {
	color: #ffffff;
	font-weight: 400;
	font-size: 1.5em;
}

.map-link-block-content a {
	display: block;
	height: 195px;
	width: 100%;

	&:hover {
		text-decoration: none;
	}
}

//Industy page
.industry-image-with-caption {
	position: relative;
}
.industry-details{
	padding-bottom: 20px;
}
.industry-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 45px;
    background-color: #084db2;
    opacity: .9;
    color: #fff;
    max-height: 45px;
    width: 100%;
    text-align: center;
}

@media (max-width: 768px) {
	.industry-title {
		// position: absolute;
		//top: 15%;
		//left: 15%;
		//transform: translate(-15%, -15%);
		// line-height: 45px;
		// background-color: #084db2;
		//opacity: .9;
		// color: #fff;
		// max-height: 45px;
		width: 100vw;
		// text-align: center;
	}
}


// Hiding the WP edit link since we aren't putting it in appropriate places for content editing on a per-template basis.
.edit-link > .post-edit-link {
	display: none;
}
