//
// Global Search Reveal Button
// --------------------------------------------------

// Variables
// ---------------------------------------
@search-reveal-button-display: 				block;
@search-reveal-button-top: 					0;
@search-reveal-button-right: 				0; // sometimes set to @hamburger-button-width
@search-reveal-button-left: 				auto;
@search-reveal-button-z-index: 				101; // Layer on top of .full-page-backdrop when search is brought up so it's still available for click

@search-reveal-button-width: 				60px;
@search-reveal-button-height: 				@header-body-height;
@search-reveal-button-line-height: 			@search-reveal-button-height;
@search-reveal-button-padding: 				0 .5em;
@search-reveal-button-margin: 				0;

@search-reveal-button-bg-color: 			transparent;
@search-reveal-button-font-size: 			1.2em;
@search-reveal-button-color: 				#FFF;

@search-reveal-button-transition: 			all .25s ease;

@search-reveal-button-bg-highlight: 		fade(#000, 10%);

// Desktop _________
@search-reveal-button-desktop-display: 			block;
@search-reveal-button-desktop-top: 				@search-reveal-button-top;
@search-reveal-button-desktop-right: 			@common-gutter;
@search-reveal-button-desktop-left: 			auto;
@search-reveal-button-desktop-z-index: 			@search-reveal-button-z-index;

@search-reveal-button-desktop-width: 			@search-reveal-button-width;
@search-reveal-button-desktop-height: 			@navbar-desktop-height;
@search-reveal-button-desktop-padding: 			@search-reveal-button-padding;
@search-reveal-button-desktop-margin: 			@search-reveal-button-margin;

@search-reveal-button-desktop-font-size: 		1em;
@search-reveal-button-desktop-color: 			@search-reveal-button-color;
// ---------------------------------------

// Styles
// ---------------------------------------
.global-search-reveal-button {
	.user-select (none);

	display: @search-reveal-button-display;

	position: absolute;
	top: @search-reveal-button-top;
	right: @search-reveal-button-right;
	left: @search-reveal-button-left;
	z-index: @search-reveal-button-z-index;

	width: @search-reveal-button-width;
	height: @search-reveal-button-height;
	line-height: @search-reveal-button-line-height;
	padding: @search-reveal-button-padding;
	border: 0;
	border-radius: 0;
	margin: @search-reveal-button-margin;

	background-color: @search-reveal-button-bg-color;

	font-size: @search-reveal-button-font-size;
	color: @search-reveal-button-color;
	text-decoration: none !important;

	.transition(@search-reveal-button-transition);

	// States
	// --------------------------
	&.is-active,
	&:focus,
	&:hover {
		background-color: @search-reveal-button-bg-highlight;
	}

	// Media Queries
	// --------------------------
	@media (min-width: @desktop-styles-min) {
		display: @search-reveal-button-desktop-display;
		top: @search-reveal-button-desktop-top;
		right: @search-reveal-button-desktop-right;
		left: @search-reveal-button-desktop-left;
		z-index: @search-reveal-button-desktop-z-index;

		width: @search-reveal-button-width;
		height: @search-reveal-button-desktop-height;
		padding: @search-reveal-button-desktop-padding;
		margin: @search-reveal-button-desktop-margin;

		font-size: @search-reveal-button-desktop-font-size;
		color: @search-reveal-button-desktop-color;
	}
}
