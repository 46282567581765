//
// Horizontal rules
// --------------------------------------------------

// Variables
// ---------------------------------------
@hr-height: 				1px;
@hr-bg-color: 				@line-divider-color;

@hr-primary-height: 		3px;
@hr-primary-bg-color: 		darken(@line-divider-color, 10%);
// ---------------------------------------


// Styles
// ---------------------------------------
hr {
	height: @hr-height;
	border: 0;
	// margin is set with the other "Common shared margins"

	background: @hr-bg-color;

	// Extensions _________
	&.primary {
		height: @hr-primary-height;

		background: @hr-primary-bg-color;
	}
}
