//
// Utilities
// --------------------------------------------------
// Classes for overriding, and to some extent for
// writing quick, inline styles. Use of !important is
// required.
// --------------------------------------------------
// For "utility" classes that assist with front-end
// development but aren’t seen as de facto overrides,
// see _helpers.less.
// --------------------------------------------------


// Variables
// --------------------------------------------------

// Font Sizes
@u-font-size-normal: 				1.0em;

@u-font-size-small: 				0.9em;
@u-font-size-smaller: 				0.8em;
@u-font-size-smallest: 				0.7em;

@u-font-size-large: 				1.1em;
@u-font-size-larger: 				1.2em;
@u-font-size-largest: 				1.3em;
// --------------------------------------------------


// Styles
// --------------------------------------------------


// Display
// ---------------------------------------
.u-inline {
	display: inline !important;
}
.u-inline-block {
	display: inline-block !important;
}
.u-block {
	display: block !important;
}
.u-flex {
	.display-flex() !important;
}
.u-inline-flex {
	.display-inline-flex() !important;
}



// Overflow
// ---------------------------------------
.u-overflow-visible { overflow: visible !important; }
.u-overflow-hidden  { overflow: hidden !important; }
.u-overflow-scroll  { overflow: scroll !important; }



// Flexbox helpers
// ---------------------------------------
// see also .u-flex and .u-inline-flex above
.u-flex-wrap {
	.flex-wrap(wrap) !important;
}
.u-align-items-center {
	.flex-align-items(center) !important;
}
.u-justify-content-center {
	.flex-justify-content(center) !important;
}



// Hiding / Showing Content
// ---------------------------------------

// Screenreader only
// ⏩⏩⏩ MOVED ⏩⏩⏩
// Moved to:
//  - _helpers.less
// Moved because:
//  - not an overriding, !important using utility
// end moved comment ⏩⏩⏩

// Basic hide / show classes
// --------------------------
.u-hide {
	display: none !important;
	visibility: hidden !important;
}
.u-show {
	display: block !important;
	visibility: visible !important;
}

// Mobile / Desktop Hiding
// --------------------------
.u-hide-mobile {
	@media (max-width: @mobile-styles-max) {
		display: none !important;
	}
}
.u-hide-desktop {
	@media (min-width: @desktop-styles-min) {
		display: none !important;
	}
}

// Grid Breakpoint Hiding
// --------------------------
.u-hide-xxs {
	@media (max-width: @screen-xxs-max) {
		display: none !important;
	}
}
.u-hide-xs {
	@media (max-width: @screen-xs-max) {
		display: none !important;
	}
}
.u-hide-sm {
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		display: none !important;
	}
}
.u-hide-md {
	@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
		display: none !important;
	}
}
.u-hide-lg {
	@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
		display: none !important;
	}
}
.u-hide-xl {
	@media (min-width: @screen-xl-min) and (max-width: @screen-xl-max) {
		display: none !important;
	}
}
.u-hide-xxl {
	@media (min-width: @screen-xxl-min) {
		display: none !important;
	}
}



// Layout & Box Model
// ---------------------------------------
.u-width-full {
	width: 100% !important;
}
.u-width-auto {
	width: auto !important;
}
.u-width-self {
	display: inline-block !important;
	width: auto !important;
}

.u-padding-0 {
	padding: 0 !important;
}
.u-padding-top-0 {
	padding-top: 0 !important;
}
.u-padding-right-0 {
	padding-right: 0 !important;
}
.u-padding-bottom-0 {
	padding-bottom: 0 !important;
}
.u-padding-left-0 {
	padding-left: 0 !important;
}

.u-border-radius-rounded {
	border-radius: @common-border-radius !important;
}
.u-border-radius-rounded-circle {
	border-radius: 50% !important;
}
.u-border-radius-0 {
	border-radius: 0 !important;
}

.u-margin-0 {
	margin: 0 !important;
}
.u-margin-top-0 {
	margin-top: 0 !important;
}
.u-margin-right-0 {
	margin-right: 0 !important;
}
.u-margin-bottom-0 {
	margin-bottom: 0 !important;
}
.u-margin-left-0 {
	margin-left: 0 !important;
}

.u-margin-center {
	margin-left:  auto !important;
	margin-right: auto !important;
}



// Floats & Clearing
// ---------------------------------------
// 🤔🤔🤔🤔 CONSIDERING DEPRECATION 🤔🤔🤔🤔
// - .pull-right
// - .pull-left
// - .no-float
// Considering deprecation because:
// - Aligning with BS4 class naming
//   - Should BS/BS4 be something that we target? Or should we be setting our own standard for these types of utilities?
//   - How do the utility-driven frameworks (e.g. Tachyons) name their utils?
//     - Would adopting one of these make it seem to the dev that we have that framework installed?
// end considering deprecation code 🤔🤔🤔🤔
.pull-right,
.u-float-right {
	float: right !important;
}
.pull-left,
.u-float-left {
	float: left !important;
}
.no-float,
.u-float-none {
	float: none !important;
}



// Font + Text helpers
// ---------------------------------------

// Font family
.u-font-family-serif 	{ font-family: @font-family-serif !important; }
.u-font-family-sans 	{ font-family: @font-family-sans !important; }
.u-font-family-clean 	{ font-family: @font-family-clean !important; }

// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
//  - the utility classes should, in their names, more clearly map to the CSS property they change
//  - replaced with the "Font Size" properties below this deprecation block
// > > > > > > > > > > > > > > > > > > > > > > > >
// Text size
.u-text-larger 			{ font-size: @u-font-size-larger !important; }
.u-text-smaller 		{ font-size: @u-font-size-small !important; }
.u-text-tiny 			{ font-size: @u-font-size-smaller !important; }
.u-text-normal 			{ font-size: @u-font-size-normal !important; }
// < < < < < < < < < < < < < < < < < < < < < < < <
// end deprecated code ☠☠☠☠

// Font Sizes
.u-font-size-inherit 	{ font-size: inheirt !important; }
.u-font-size-normal 	{ font-size: @u-font-size-normal !important; }

.u-font-size-small 		{ font-size: @u-font-size-small !important; }
.u-font-size-smaller 	{ font-size: @u-font-size-smaller !important; }
.u-font-size-smallest 	{ font-size: @u-font-size-smallest !important; }

.u-font-size-large 		{ font-size: @u-font-size-large !important; }
.u-font-size-larger 	{ font-size: @u-font-size-larger !important; }
.u-font-size-largest 	{ font-size: @u-font-size-largest !important; }

// Text formatting
// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
//  - These more clearly map to "font-weight" naming. New classes added below.
// > > > > > > > > > > > > > > > > > > > > > > > >
.u-text-bold			{ font-weight: bold !important; }
.u-text-italic			{ font-style: italic !important; }
.u-text-normal			{ font-weight: normal !important; font-style: normal !important; }
// < < < < < < < < < < < < < < < < < < < < < < < <
// end deprecated code ☠☠☠☠
.u-font-weight-bold		{ font-weight: bold !important; }
.u-font-weight-italic	{ font-style: italic !important; }
.u-font-weight-normal	{ font-weight: normal !important; }

.u-text-lowercase 		{ text-transform: lowercase !important; }
.u-text-uppercase 		{ text-transform: uppercase !important; }
.u-text-capitalize 		{ text-transform: capitalize !important; }

.u-text-underline 		{ text-decoration: underline !important; }
.u-text-strikethrough	{ text-decoration: line-through !important; }
.u-text-undecorated 	{ text-decoration: none !important; }

// Text alignment
.u-text-center 			{ text-align: center !important; }
.u-text-left   			{ text-align: left !important; }
.u-text-right  			{ text-align: right !important; }

// Text colors
.u-text-color-alert 	{ color: @alert-color !important; }
.u-text-color-warning 	{ color: @feedback-warning-color !important; }
.u-text-color-error,
.u-text-color-danger 	{ color: @feedback-error-color !important; }
.u-text-color-success 	{ color: @feedback-success-color !important; }
.u-text-color-info 		{ color: @feedback-info-color !important; }

.u-text-color-default 	{ color: @global-text-color !important; }
