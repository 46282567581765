//
// Animations
// --------------------------------------------------


// "Pulse" animation for focus assistance
// ---------------------------------------
@pulseSize: 1.05;

@keyframes pulse {
	50% { transform: scale( @pulseSize ); }
}

// Big pulse
// --------------------------
@bigPulseSize: 1.2;

@keyframes bigPulse {
	50% { transform: scale( @bigPulseSize ); }
}


// Fade In Animation
// ---------------------------------------
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}


// Fade Out Animation
// ---------------------------------------
@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}


// Fade In & Up Animation
// ---------------------------------------
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(-50px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}


// "Levitate" object animation
// ---------------------------------------
@keyframes levitate {
	to {
		transform: translateY(-20%);
	}
}

// Usage:
// --------------------------
// Syntax: <name> || <time> || <timing-function> || <delay> || <iteration-count> || <direction> || <fill-mode>
// .animation (levitate 3s ease 0s infinite alternate both);


// Rotate full 360 degrees
// ---------------------------------------
@keyframes full-rotation {
	to {
		transform: rotate(360deg);
	}
}


// Shake animation
// ---------------------------------------
@keyframes shake {
	from, to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	10%, 30%, 50%, 70%, 90% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	20%, 40%, 60%, 80% {
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0);
	}
}


// Zoom In animation
// ---------------------------------------
@keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}

	50% {
		opacity: 1;
	}
}


// Jump link target animation
// ---------------------------------------
// ⏩⏩⏩ MOVED ⏩⏩⏩
// Moved to:
//  - a consolidated Library file (in components). components/library/_jump-link-target.less
