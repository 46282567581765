//
// Responsive Tables
// --------------------------------------------------


// Variables
// ---------------------------------------
@responsive-table-margin-bottom: 		@common-element-margin-bottom;
@responsive-table-bg-color: 			#FFFFFF;
@responsive-table-shadow-bg-color: 		#C3C3C3;

@responsive-table-bg-size-1: 			4em;
@responsive-table-bg-size-2: 			1em;
// ---------------------------------------


// Styles
// ---------------------------------------
.responsive-table-wrapper {
	// Local vars for legibility
	@rtbg: 		@responsive-table-bg-color;
	@rtsbg: 	@responsive-table-shadow-bg-color;

	max-width: 100%;
	min-height: 0.01%;
	margin-bottom: @responsive-table-margin-bottom;
	overflow: auto;

	// dynamic shadow effect _________
	overflow-x: auto;
	background-image:
		linear-gradient(to right, @rtbg,  fade(@rtbg, 0%)),
		linear-gradient(to left,  @rtbg,  fade(@rtbg, 0%)),
		linear-gradient(to right, @rtsbg, fade(@rtsbg, 0%)),
		linear-gradient(to left,  @rtsbg, fade(@rtsbg, 0%));
	background-position:
		0 0,
		100% 0,
		0 0,
		100% 0;
	background-repeat: no-repeat;
	background-color: white;
	background-size:
		@responsive-table-bg-size-1 100%,
		@responsive-table-bg-size-1 100%,
		@responsive-table-bg-size-2 100%,
		@responsive-table-bg-size-2 100%;
	background-attachment:
		local,
		local,
		scroll,
		scroll;

	> table {
		width: 100%;
		// Note: min-width can be set* to a certain value to guarantee that the table stretches to a certain width. Might be helpful to stretch out tables that the browser doesn't see the need to stretch out so much. * Setting min-width might be best done inline on a table-per-table basis. Consider setting with (r)em values so the table width will scale along with the type.
		margin-top: 0;
		margin-bottom: 0;
	}
}
