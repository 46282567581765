//
// Grid List
// --------------------------------------------------


// Variables
// ---------------------------------------
@grid-list-margin-bottom: 				@common-vertical-gutter;
@grid-list-fallback-inline-block-right-spacing: 	-.25em;
// ---------------------------------------


// Styles
// ---------------------------------------
.grid-list {
	.strip_list();
	// .clear_float();

	.display-flex();
	.flex-wrap(wrap);
}

.grid-list__item {
	margin-bottom: @grid-list-margin-bottom;

	// Kinda lazy fallback layout
	.mod-no-flexbox & {
		float: none; // cancel the float
		display: inline-block; // make 'em inline
		margin-right: @grid-list-fallback-inline-block-right-spacing; // overcome the inline-block implied spacing to the right
		vertical-align: top; // move all items to top for alignment

		// &:last-child { margin-right: 0; } // Not sure if necessary
	}
}
