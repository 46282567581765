//
// Social icon listing
// --------------------------------------------------


// Variables
// ---------------------------------------
@social-icons-item-margin: 						0 0 0.25em 0;

@social-icon-link-padding: 						0.125em;
@social-icon-link-font-size: 					32px;
@social-icon-link-transition:					@link-transition;
@social-icon-link-highlight-bg-color:			#FFF;

@social-icon-link-subtle-highlight-bg-color: 	transparent;
@social-icon-link-subtle-color: 				@link-color;
// ---------------------------------------


// Styles
// ---------------------------------------
.social-icons {
	.display-flex();
	.flex-wrap(wrap);
	padding: 0;
	margin: 0;
	list-style: none;

	> li {
		margin: @social-icons-item-margin;
	}
}

.social-icon-link {
	display: block;
	padding: @social-icon-link-padding;

	text-align: center;
	font-size: @social-icon-link-font-size;
	text-decoration: none !important;

	.transition(@social-icon-link-transition);

	// Icon Colors _________
	&.facebook 		{ color: @facebook-color; }
	&.twitter 		{ color: @twitter-color; }
	&.rss 			{ color: @rss-color; }
	&.youtube 		{ color: @youtube-color; }
	&.vimeo 		{ color: @vimeo-color; }
	&.google-plus 	{ color: @google-plus-color; }
	&.linkedin 		{ color: @linkedin-color; }
	&.pinterest 	{ color: @pinterest-color; }
	&.instagram 	{ color: @instagram-color; }
	&.mail 			{ color: @mail-color; }

	// Interactions _________
	&:focus,
	&:hover {
		background-color: @social-icon-link-highlight-bg-color;
	}
}


// Extensions
// --------------------------
.social-icon-link--reversed {
	background-color: currentColor;

	&::before { color: #FFF; }

	// Interactions _________
	&:focus,
	&:hover {
		&::before { color: currentColor; }
	}
}

.social-icon-link--subtle {
	&::before { color: @social-icon-link-subtle-color; }

	// Interactions _________
	&:focus,
	&:hover {
		background-color: @social-icon-link-subtle-highlight-bg-color;

		&::before { color: currentColor; }
	}
}
