//
// Grid - Extensions to BS3 grid + customizations
// --------------------------------------------------


// Containers
// --------------------------------------------------

// Variables
// ---------------------------------------
@perch-grid-container-min-width: 			@site-min-width;
@perch-grid-container-max-width: 			@site-max-width;
// ---------------------------------------

// Styles
// ---------------------------------------
.container {
	// 🤔🤔🤔🤔 CONSIDERING DEPRECATION 🤔🤔🤔🤔
	// considering Deprecation because:
	//  - not sure that setting position: relative is doing anything good here. at least worth putting in a variable
	//  - not sure setting min-width is doing any good either
	position: relative;

	min-width: @perch-grid-container-min-width;
	// end considering deprecation code 🤔🤔🤔🤔


	// An override for the stock Bootstrap grid's "stairstepping" container sizes. This makes all containers fluid by default.
	width: auto;

	// 💀💀💀💀 REMOVED 💀💀💀💀
	// - as far as I can tell, having the media query set the max width...at the max width... is duplicated work. The max-width will only matter at that max width on its own. The only reason I'm leaving this comment is because there is a remote possibility that this was being used for some unforeseen reason. Worth fully removing in 1.0 if no effects are noticed.
	// @media (min-width: @perch-grid-container-max-width) {
	// 	max-width: @perch-grid-container-max-width;
	// }
	// end REMOVED 💀💀💀💀
	max-width: @perch-grid-container-max-width;
}

// Container with no max-width
// --------------------------
// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
//  - not sure that setting position: relative is doing anything good here. at least worth putting in a variable
//  - not sure setting min-width is doing any good either
//  - having .container-fluid here in the Perch grid file is kind of a misnomer; the plain Bootstrap-derived .container class has been made to be fluid by default.
.container-fluid {
	position: relative;

	min-width: @perch-grid-container-min-width;
}
// end deprecated code ☠☠☠☠

// Container whose max-width fits to the designated XL size
// --------------------------
// Useful as an alternate container width (makes for a wider container if -xl- is greater than @perch-grid-container-max-width; makes for a thinner container otherwise)
// --------------------------
.container-xl {
	width: auto;
	max-width: @container-xl;
}

// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
//  - "Super" is an unhelpful name
//  - Action taken: Super container now gets its definition and values from the new, ".wider-container" class

// Custom Super-wide Container
// --------------------------
@super-container-width: @wider-container-width;

.super-container {
	.wider-container;
}
// end deprecated code ☠☠☠☠


// Custom Wider Container
// --------------------------
@wider-container-width: 			(@perch-grid-container-max-width + 240px);

.wider-container {
	max-width: @wider-container-width;
}

// Container that does not have gutters
// --------------------------
// 🤔🤔🤔🤔 CONSIDERING DEPRECATION 🤔🤔🤔🤔
// considering Deprecation because:
//  - this is a unique class just reproducing the functionality of existing utility classes, ".no-padding-right", ".no-padding-left"
.container-gutterless {
	padding-left:  0 !important;
	padding-right: 0 !important;
}
// end considering deprecation code 🤔🤔🤔🤔


// « Container Break Out! »
// --------------------------
// ☠☠☠☠ DEPRECATED ☠☠☠☠
// Deprecated because:
// > > > > > > > > > > > > > > > > > > > > > > > >
//  - too specific to the way we've done grids and containers
// ! Replaced with:
//  - a new helper class .make-full-width (found in _helpers.less)
// < < < < < < < < < < < < < < < < < < < < < < < <
.container-break-out {
	width: 100%; // Fallback
	width: 100vw;

	margin-left: -(@grid-gutter-width / 2);

	@media (min-width: @perch-grid-container-max-width) {
		margin-left: -webkit-calc(~"(-100vw + 100%) / 2");
		margin-left:    -moz-calc(~"(-100vw + 100%) / 2");
		margin-left:         calc(~"(-100vw + 100%) / 2");
	}
}
// end deprecated code ☠☠☠☠



// Column base styles
// --------------------------------------------------

// Column styles are already set in _bootstrap-grid.less, however re-setting this here has advantages:
// 1. As additional grid sizes are added (-xl- is added below), a general col-foo selector ensures that all of them have the same base styles
// 2. This provides an explicit hook for the LESS :extend() feature, used in the .row--thinner .row [class*="col-"] case
[class*="col-"] {
	position: relative;
	// Prevent columns from collapsing when empty
	min-height: 1px;
	// Inner gutter via padding
	padding-left:  (@grid-gutter-width / 2);
	padding-right: (@grid-gutter-width / 2);
}



// XS size specific styling/layout adjustments
// --------------------------------------------------
// To turn all these off, set the viewport variable to a pixel value below 320px
// --------------------------------------------------
@viewport-width-mobile-grid-layout-change: 			@screen-xs-max; // Set < 320px to turn off

@media (max-width: @viewport-width-mobile-grid-layout-change) {
	// Reduce container gutter size
	.container {
		padding-left:  @mobile-container-gutter;
		padding-right: @mobile-container-gutter;
	}

	// Reduce row negative margins
	.row {
		margin-left:  -(@mobile-column-gutter);
		margin-right: -(@mobile-column-gutter);
	}

	// Reduce gutter size between columns
	[class*="col-"] {
		padding-left:  @mobile-column-gutter;
		padding-right: @mobile-column-gutter;
	}
}



// Thinner Row Helper class
// --------------------------------------------------
.row--thinner {
	margin-left:  -(@grid-gutter-width--thinner / 2);
	margin-right: -(@grid-gutter-width--thinner / 2);

	// Removed direct descendent selector on this because it was forcing columns ... to be direct descendents. This seems an unnecessary burden to put on the markupper. But that does mean we now need a way to "reset" row & column children to the .row-thinner row. See the below &:extend() selector for child row columns. -- KB 2017-01-18
	[class*="col-"] {
		padding-left:  (@grid-gutter-width--thinner / 2);
		padding-right: (@grid-gutter-width--thinner / 2);
	}

	// Child row's column elements are "reset" to default column styles — this uses LESS' extend() feature, cf. http://lesscss.org/features/#extend-feature
	.row:not(.row--thinner) [class*="col-"] {
		&:extend([class*="col-"]);
	}
}



// Extra Large grid
// An extension of the BootStrap 3 grid. Adding a greater range for more control.
//
// Columns, offsets, pushes, and pulls for the extra large desktop device range.
//
// Note that `.col-lg-12` doesn't get floated on purpose--there's no need since
// it's full-width.
// --------------------------------------------------

@media (min-width: @screen-xl-min) {
	.col-xl-1,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-10,
	.col-xl-11 {
		float: left;
	}
	.col-xl-1  { width: percentage((1 / @grid-columns)); }
	.col-xl-2  { width: percentage((2 / @grid-columns)); }
	.col-xl-3  { width: percentage((3 / @grid-columns)); }
	.col-xl-4  { width: percentage((4 / @grid-columns)); }
	.col-xl-5  { width: percentage((5 / @grid-columns)); }
	.col-xl-6  { width: percentage((6 / @grid-columns)); }
	.col-xl-7  { width: percentage((7 / @grid-columns)); }
	.col-xl-8  { width: percentage((8 / @grid-columns)); }
	.col-xl-9  { width: percentage((9 / @grid-columns)); }
	.col-xl-10 { width: percentage((10/ @grid-columns)); }
	.col-xl-11 { width: percentage((11/ @grid-columns)); }
	.col-xl-12 { width: 100%; }

	// Push and pull columns for source order changes
	.col-xl-push-0  { left: auto; }
	.col-xl-push-1  { left: percentage((1 / @grid-columns)); }
	.col-xl-push-2  { left: percentage((2 / @grid-columns)); }
	.col-xl-push-3  { left: percentage((3 / @grid-columns)); }
	.col-xl-push-4  { left: percentage((4 / @grid-columns)); }
	.col-xl-push-5  { left: percentage((5 / @grid-columns)); }
	.col-xl-push-6  { left: percentage((6 / @grid-columns)); }
	.col-xl-push-7  { left: percentage((7 / @grid-columns)); }
	.col-xl-push-8  { left: percentage((8 / @grid-columns)); }
	.col-xl-push-9  { left: percentage((9 / @grid-columns)); }
	.col-xl-push-10 { left: percentage((10/ @grid-columns)); }
	.col-xl-push-11 { left: percentage((11/ @grid-columns)); }

	.col-xl-pull-0  { right: auto; }
	.col-xl-pull-1  { right: percentage((1 / @grid-columns)); }
	.col-xl-pull-2  { right: percentage((2 / @grid-columns)); }
	.col-xl-pull-3  { right: percentage((3 / @grid-columns)); }
	.col-xl-pull-4  { right: percentage((4 / @grid-columns)); }
	.col-xl-pull-5  { right: percentage((5 / @grid-columns)); }
	.col-xl-pull-6  { right: percentage((6 / @grid-columns)); }
	.col-xl-pull-7  { right: percentage((7 / @grid-columns)); }
	.col-xl-pull-8  { right: percentage((8 / @grid-columns)); }
	.col-xl-pull-9  { right: percentage((9 / @grid-columns)); }
	.col-xl-pull-10 { right: percentage((10/ @grid-columns)); }
	.col-xl-pull-11 { right: percentage((11/ @grid-columns)); }

	// Offsets
	.col-xl-offset-0  { margin-left: 0; }
	.col-xl-offset-1  { margin-left: percentage((1 / @grid-columns)); }
	.col-xl-offset-2  { margin-left: percentage((2 / @grid-columns)); }
	.col-xl-offset-3  { margin-left: percentage((3 / @grid-columns)); }
	.col-xl-offset-4  { margin-left: percentage((4 / @grid-columns)); }
	.col-xl-offset-5  { margin-left: percentage((5 / @grid-columns)); }
	.col-xl-offset-6  { margin-left: percentage((6 / @grid-columns)); }
	.col-xl-offset-7  { margin-left: percentage((7 / @grid-columns)); }
	.col-xl-offset-8  { margin-left: percentage((8 / @grid-columns)); }
	.col-xl-offset-9  { margin-left: percentage((9 / @grid-columns)); }
	.col-xl-offset-10 { margin-left: percentage((10/ @grid-columns)); }
	.col-xl-offset-11 { margin-left: percentage((11/ @grid-columns)); }
}
