//
// Browser Fixes
// --------------------------------------------------


// IE 10 Viewport Fixes
// ---------------------------------------

// Fix for IE 10 Desktop not respecting viewport meta tag
// cf. https://github.com/h5bp/html5-boilerplate/issues/1047
@-ms-viewport {
	width: device-width;
}

// Above fix breaks IE Mobile v10. Fix-fix:
// cf. https://github.com/h5bp/html5-boilerplate/pull/1243
@media screen and (max-width: 400px) {
	@-ms-viewport {
		width: 320px;
	}
}



// Browser messages
// --------------------------------------------------


// Variables
// ---------------------------------------
@browser-message-max-width: 			@site-max-width;
@browser-message-padding: 				3.5% 1em;
@browser-message-margin: 				auto auto @common-vertical-gutter;
@browser-message-bg-color: 				#EECFCF;
@browser-message-text-align: 			center;
@browser-message-line-height: 			1.5;
@browser-message-color: 				#B94A48;
// ---------------------------------------


// Styles
// ---------------------------------------
.browser-message {
	display: none;

	max-width: @browser-message-max-width;
	padding: @browser-message-padding;
	margin: @browser-message-margin;

	background-color: @browser-message-bg-color;;
	text-align: @browser-message-text-align;
	line-height: @browser-message-line-height;
	color: @browser-message-color;

	// Unhiding logic for different browser-message types
	// --------------------------
	&.browser-outdated-message {
		// Show this hidden element if using an outdated browser _________

		// Chrome before 49, old IE's
		// https://caniuse.com/#feat=picture
		.mod-no-picture & {
			display: block !important;
		}
	}
	&.browser-no-js-message {
		// Show this hidden element if using a browser with JS turned off or blocked _________
		.mod-no-js & {
			display: block !important;
		}
	}
}

// Exception for IE11+ Internet Explorer 11+
_:-ms-fullscreen, :root.mod-no-picture .browser-message.browser-outdated-message { display: none !important; }
