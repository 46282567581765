//
// Site Variables
// --------------------------------------------------


// Paths
// ---------------------------------------
@site-path: 							"/content/themes/birdpress-theme";
@perch-dir: 							"/assets/third-party/perch";
@icomoon-font-path:         			"../icons/fonts";


// Typography
// ---------------------------------------
// @global-text-color: 					#494949;
@font-family-sans: 			'Poppins', Freesans, Helmet, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@font-family-alternate: 	'Coda', Freesans, Helmet, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

@h3-font-size:				1em;
@h3-margin:					1rem 0;


// Site Colors @birdpress-color-pink
// ---------------------------------------
@birdpress-color-pink: 					#D8214E;
@birdpress-color-purple: 				#25292c;

@site-color-primary: 					#fff;
@site-color-secondary: 					#084db2;

@site-color-secondary-highlight:      lighten(@site-color-secondary, 10%);
@site-color-secondary-shade:          darken(@site-color-secondary, 40%);

@global-search-overlay-bg-color: 		fade(#333, 75%);

@header-bg-color:						#efefef;

@link-highlight:						#757575;


// Buttons
//----------------------------------------
//Primary Button - Blue
@button-padding: 						0px 3em;
@button-height:  						3.5em;
@button-line-height:					3.5em; // Helps with appearance of vertically centered text
@button-border-width:					0;
@button-border-color:					#074fb1;
@button-border-radius:					4px;
@button-font-family: 					@font-family-alternate;
@button-font-size:						1em;
@button-font-weight:					300;
@button-text-transform:					uppercase;
@button-text-color:						#fff;
@button-text-shadow:					none;
//background-color:
@button-color:							#074fb1;
@button-box-shadow:						none;

//Secondary button background and border color - White
@button-secondary-color:				#ffffff;
@button-secondary-text-color: 			#000000;
@button-seconday-box-shadow:			none;
@button-secondary-highlight:			#757575;
@button-secondary-shade:				#ffffff;
//Subtle button
@button-subtle-text-color:				#51adef;
@button-subtle-box-shadow:				none;
@button-subtle-highlight:				#26292C;
@button-subtle-text-shadow: 			none;



// Forms
//---------------------------------------
@input-border-color:					#074eb0;
@input-border-width:					thin;
@input-border-radius:					4px;
@input-box-shadow:						none;
//0 0.2em 0.5em rgba(0, 0, 0, 0.05)
@input-padding:							1em 0.7em;
@input-bg:								transparent;
@input-color-placeholder:				#dcdbdb;


// Mobile Columns / Gutters
// ---------------------------------------
@mobile-column-gutter:				10px;
@mobile-container-gutter:			10px;



// Containers
// ---------------------------------------
@container-xxl: 						1920px;


// Header / Nav / Logo / Footer
// --------------------------------------- @search-reveal-button-width taken out cause no search button

@site-max-width:						1260px;
@header-body-height: 					60px;
@header-body-desktop-height: 			138px;

@header-desktop-padding:				0px;
@global-main-nav-desktop-margin: 		0 25px 0 0;
@header-desktop-height:					138px;
@header-desktop-line-height: 			138px;

@navbar-height:						 	60px;
@navbar-desktop-height: 				138px;
@global-main-nav-desktop-margin:		0;

@link-hover-text-decoration:			none;


@global-main-nav-link-font-size:				11px;
@global-main-nav-link-desktop-text-transform:	uppercase;
@global-nav-link-text-transform:				uppercase;
@global-main-nav-link-desktop-font-weight:		normal;
@global-main-nav-link-desktop-text-decoration:	none;
@global-main-nav-link-desktop-color:			#000000;
@global-outer-navigation-wrapper-bg-color: 		#25292c;
@global-nav-active-parent-desktop-text-color:	#074fb1;
@global-nav-active-parent-desktop-bg-color: 	transparent;
@global-nav-link-desktop-highlight-bg-color:	transparent;
@global-nav-link-desktop-highlight-color:		#074fb1;

//mobile menu - hamburger
@hamburger-bar-color:							#25292c;


@global-logo-top: 						15px;
@global-logo-left:						40px;
@global-logo-width: 					200px;

@global-logo-desktop-top: 				45px;
@global-logo-desktop-width: 			300px;

@footer-bg-color: 						#25292c;
@extended-footer-bg-color: 				#DDD;
@footer-padding-top:					1%;
@footer-padding-bottom:					0px;
