//
// Headings
// --------------------------------------------------

// Variables
// ---------------------------------------
@heading-font-family: 		inherit;
@heading-font-weight: 		bold;
@heading-line-height: 		1.1;
@heading-color: 			inherit;
@heading-text-transform: 	none;

@h1-margin: 				0 0 @common-vertical-gutter 0; // old bottom 0.520em
@h2-margin: 				0 0 @common-vertical-gutter 0; // old bottom 0.635em
@h3-margin: 				0 0 @common-vertical-gutter 0; // old bottom 0.817em
@h4-margin: 				0 0 @common-vertical-gutter 0; // old bottom 1.143em
@h5-margin: 				0 0 @common-vertical-gutter 0; // old bottom 1.429em
@h6-margin: 				0 0 @common-vertical-gutter 0; // old bottom 1.681em

@h1-font-size: 				2.35rem;
@h2-font-size: 				1.75rem;
@h3-font-size: 				1.40rem;
@h4-font-size: 				1.20rem;
@h5-font-size: 				1.00rem;
@h6-font-size: 				0.85rem;
// ---------------------------------------


// Styles
// ---------------------------------------
h1, h2, h3, h4, h5, h6,
.like-h1, .like-h2, .like-h3, .like-h4, .like-h5, .like-h6 {
	font-family: @heading-font-family;
	font-weight: @heading-font-weight;
	line-height: @heading-line-height;
	color: @heading-color;
	text-transform: @heading-text-transform;
	.font-smoothing ();
	.kern-me ();
}
h1, .like-h1 {
	margin: @h1-margin;

	font-size: @h1-font-size;
}
h2, .like-h2 {
	margin: @h2-margin;

	font-size: @h2-font-size;
}
h3, .like-h3 {
	margin: @h3-margin;

	font-size: @h3-font-size;
}
h4, .like-h4 {
	margin: @h4-margin;

	font-size: @h4-font-size;
}
h5, .like-h5 {
	margin: @h5-margin;

	font-size: @h5-font-size;
}
h6, .like-h6 {
	margin: @h6-margin;

	font-size: @h6-font-size;
}
