//
// Layout - Desktop
// --------------------------------------------------



// Desktop Variables
// ---------------------------------------
// Set here instead of within the media query because setting variables there
// prevents them from being able to be overriden outside of the media query scope
// ---------------------------------------

// Header
// --------------------------
@header-desktop-position: 						@header-position;
@header-desktop-top: 							-(@header-desktop-height);
@header-desktop-left: 							@header-left;
@header-desktop-right: 							@header-right;
@header-desktop-z-index: 						@header-z-index;

// @header-desktop-height: 						»»» set in variables.less
@header-desktop-line-height: 					@header-desktop-height;
@header-desktop-padding: 						@utility-nav-desktop-height 0 0;

@header-desktop-bg-color: 						@header-bg-color;
@header-desktop-text-color: 					@header-text-color;

@header-desktop-transition: 					@header-transition;

// States
@header-desktop-show-top: 						@header-show-top;


// Navigation Wrapper
// --------------------------
@global-outer-navigation-wrapper-desktop-z-index: auto;
@header-navigation-container-desktop-padding: 	0 @common-gutter;


// Base Nav
// --------------------------
@base-nav-desktop-flex-justify-content: 		flex-end;

@global-nav-active-parent-desktop-bg-color: 	@global-nav-link-desktop-highlight-bg-color;
@global-nav-active-parent-desktop-text-color: 	@global-nav-link-desktop-highlight-color;


// Utility Nav
// --------------------------

// outer nav element
@utility-nav-desktop-position: 					absolute;
@utility-nav-desktop-top: 						-@utility-nav-desktop-height;
@utility-nav-desktop-left: 						0;
@utility-nav-desktop-right: 					0;
@utility-nav-desktop-z-index: 					11;
// @utility-nav-desktop-height: 				»»» set in variables.less
@utility-nav-desktop-bg-color: 					@utility-nav-bg-color;

// outer nav container
@utility-nav-container-desktop-padding: 		0 @common-gutter;

// nav list
@utility-nav-list-desktop-padding: 				0;
@utility-nav-list-desktop-margin: 				0;
@utility-nav-list-desktop-text-align: 			left;

// nav item
@utility-nav-item-desktop-padding: 				0;
@utility-nav-item-desktop-margin: 				0 -0.25em 0 0;
@utility-nav-item-desktop-border-top: 			0;
@utility-nav-item-desktop-border-bottom: 		0;

// nav link
@utility-nav-link-desktop-height: 				@utility-nav-desktop-height;
@utility-nav-link-desktop-line-height: 			@utility-nav-desktop-height;
@utility-nav-link-desktop-padding: 				0 0.75em;
@utility-nav-link-desktop-font-family: 			inherit;
@utility-nav-link-desktop-font-size: 			0.9em;
@utility-nav-link-desktop-text-transform: 		none;
@utility-nav-link-desktop-font-weight: 			normal;
@utility-nav-link-desktop-color: 				@utility-nav-link-color;

@utility-nav-link-desktop-bg-highlight: 		fade(#000, 30%);
@utility-nav-link-desktop-highlight: 			@utility-nav-link-highlight;


// Main Nav
// --------------------------

// outer nav element
@global-main-nav-desktop-float: 				right;
@global-main-nav-desktop-height: 				@navbar-desktop-height;
@global-main-nav-desktop-padding: 				0;
@global-main-nav-desktop-margin: 				0 0 0 0;

// nav list
@global-main-nav-list-desktop-text-align: 		left;

// nav item
@global-main-nav-item-desktop-padding: 			0;
@global-main-nav-item-desktop-margin: 			0;
@global-main-nav-item-desktop-border-top: 		0;
@global-main-nav-item-desktop-border-bottom: 	0;

// nav link
@global-main-nav-link-desktop-height: 			@navbar-desktop-height;
@global-main-nav-link-desktop-line-height: 		@navbar-desktop-height;
@global-main-nav-link-desktop-padding: 			0 0.85em;
@global-main-nav-link-desktop-font-size: 		0.9em;
@global-main-nav-link-desktop-text-transform: 	none;
@global-main-nav-link-desktop-font-weight: 		bold;
@global-main-nav-link-desktop-color: 			@global-nav-link-color;

@global-nav-link-desktop-highlight-bg-color: 	darken(@header-desktop-bg-color, 7%);
@global-nav-link-desktop-highlight-color: 		@global-main-nav-link-desktop-color;


// Child Lists
// --------------------------
@global-nav-child-list-desktop-position: 		absolute;
@global-nav-child-list-desktop-top: 			90%;
@global-nav-child-list-desktop-left: 			auto;
@global-nav-child-list-desktop-right: 			0;
@global-nav-child-list-desktop-z-index: 		1000;
@global-nav-child-list-desktop-min-width: 		320px;
@global-nav-child-list-desktop-padding: 		0;
@global-nav-child-list-desktop-margin: 			0;
@global-nav-child-list-desktop-border-top: 		0;
@global-nav-child-list-desktop-border-bottom: 	0;
@global-nav-child-list-desktop-bg-color: 		@global-nav-link-desktop-highlight-bg-color;
@global-nav-child-list-desktop-text-align: 		right;
@global-nav-child-list-desktop-transition: 		top .175s ease .075s,
												opacity .175s ease .075s,
												max-height .175s linear .075s;
@global-nav-child-list-desktop-box-shadow: 		0 .25em .25em 0 fade(#000, 25%);

@global-nav-child-list-desktop-active-top: 		100%;
@global-nav-child-list-desktop-active-transition: top .175s ease .075s,
												  opacity .175s ease .075s,
												  max-height .175s linear .075s;

// child list item
@global-nav-child-list-item-desktop-float: 			none;
@global-nav-child-list-item-desktop-border-top: 	0;
@global-nav-child-list-item-desktop-border-bottom: 	0;

// child list link
@global-nav-child-list-link-desktop-line-height: 	1.2;
@global-nav-child-list-link-desktop-padding: 		1em 1em;
@global-nav-child-list-link-desktop-font-size: 		0.9em;
@global-nav-child-list-link-desktop-font-weight: 	@global-main-nav-link-desktop-font-weight;
@global-nav-child-list-link-desktop-text-transform: @global-main-nav-link-desktop-text-transform;
@global-nav-child-list-link-desktop-color: 			@global-main-nav-link-desktop-color;

@global-nav-child-list-link-desktop-bg-highlight: 	darken(@global-nav-link-desktop-highlight-bg-color, 7%);
@global-nav-child-list-link-desktop-highlight: 		@global-nav-child-list-link-desktop-color;



// Child List Opening Behaviors
// --------------------------
@child-nav-opener-desktop-display: 				none;


// // Logo
// // --------------------------
// // »»» Moved to _global-logo.less

// ---------------------------------------



// Desktop media query
// ---------------------------------------
@media (min-width: @desktop-styles-min) {

	// Header
	// ---------------------------------------
	.global-header {
		position: @header-desktop-position;
		top: @header-desktop-top;
		left: @header-desktop-left;
		right: @header-desktop-right;
		z-index: @header-desktop-z-index;

		height:      @header-desktop-height;
		line-height: @header-desktop-line-height;
		padding: @header-desktop-padding;

		background-color: @header-desktop-bg-color;
		color: @header-desktop-text-color;

		.transition (@header-desktop-transition);

		// States _________
		&.show {
			top: @header-desktop-show-top;
		}
	}



	// Navigation Wrapper
	// ---------------------------------------
	.global-outer-navigation-wrapper {
		// undo the hiding for mobile
		display: block;
		max-height: none;
		margin-top: 0;
		overflow-y: visible !important;

		// Reset positioning (note: positioning @ desktop is set for each individual nav)
		position: static;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		z-index: @global-outer-navigation-wrapper-desktop-z-index;

		// Remove color (note: bg-color @ desktop can be set on header or main navigation)
		background-color: transparent;

		// Remove animations & transforms
		.transform(translate(0));
		.animation(none) !important;

		// Children
		// --------------------------
		> .container {
			padding: @header-navigation-container-desktop-padding;
		}
	}



	// Base Nav
	// ---------------------------------------
	// note: base nav styles @ desktop are overridden by _utility-nav_ and _main-nav_,
	// so base nav variables are not replicated here.
	// ---------------------------------------
	// .global-nav {
	// 	//
	// }

	.global-nav-list {
		.animation (none) !important;

		// Conditional styles _________
		// Flexbox layout replaces buggy/wonky inline-block method
		.mod-flexbox & {
			.display-flex ();
			.flex-justify-content (@base-nav-desktop-flex-justify-content);

			> .global-nav-item {
				display: block;
				margin-right: 0;
			}

			// Reset layout / remove flexbox for child lists
			.global-nav-list {
				display: block;
				.flex-justify-content (flex-start);
			}
		}
	}

	.global-nav-item {
		display: inline-block;
		vertical-align: top;
		margin-right: -0.25em;

		// Contitional styles _________
		&:last-child {
			margin-right: 0;
		}
	}

	.global-nav-link {

		// Conditional Parentage styles
		// --------------------------

		// Active Parent & Active Page _________
		.active-parent > &,
		.active > & {
			background-color: @global-nav-active-parent-desktop-bg-color;

			color: @global-nav-active-parent-desktop-text-color;
		}
	}



	// Utility Nav
	// ---------------------------------------
	.global-utility-nav {
		position: @utility-nav-desktop-position;
		top: @utility-nav-desktop-top;
		left: @utility-nav-desktop-left;
		right: @utility-nav-desktop-right;
		z-index: @utility-nav-desktop-z-index;

		height: @utility-nav-desktop-height;
		line-height: @utility-nav-desktop-height;

		// Not sure what this did... --> KB 2016-06-15
		// margin-right: 0;

		background-color: @utility-nav-desktop-bg-color;

		// Children
		// --------------------------
		> .container {
			padding: @utility-nav-container-desktop-padding;
		}
	}

	.global-utility-nav-list {
		padding: @utility-nav-list-desktop-padding;
		margin: @utility-nav-list-desktop-margin;

		text-align: @utility-nav-list-desktop-text-align;
	}

	.global-utility-nav-item {
		// pretty sure this is just inherited from being a .global-nav-item
		// display: inline-block;

		// Can't see that this was resetting anything
		// height: auto;

		padding: @utility-nav-item-desktop-padding;
		margin: @utility-nav-item-desktop-margin;
		border-top: @utility-nav-item-desktop-border-top;
		border-bottom: @utility-nav-item-desktop-border-bottom;

		&:last-child {
			margin-right: 0;
		}
	}

	.global-utility-nav-link {
		height: @utility-nav-link-desktop-height;
		line-height: @utility-nav-link-desktop-line-height;
		padding: @utility-nav-link-desktop-padding;

		font-family: @utility-nav-link-desktop-font-family;
		font-size: @utility-nav-link-desktop-font-size;
		text-transform: @utility-nav-link-desktop-text-transform;
		font-weight: @utility-nav-link-desktop-font-weight;
		color: @utility-nav-link-desktop-color;

		// States _________
		.submenu-is-open > &,
		.global-nav-item:focus > &,
		.global-nav-item:hover > & {
			background-color: @utility-nav-link-desktop-bg-highlight;
			color: @utility-nav-link-desktop-highlight;
		}
	}



	// Main Nav
	// ---------------------------------------
	.global-main-nav {
		.clear_float ();
		float: @global-main-nav-desktop-float;
		height: @global-main-nav-desktop-height;
		padding: @global-main-nav-desktop-padding;
		margin: @global-main-nav-desktop-margin;
	}

	.global-main-nav-list {
		text-align: @global-main-nav-list-desktop-text-align;
	}

	.global-main-nav-item {
		padding: @global-main-nav-item-desktop-padding;
		margin: @global-main-nav-item-desktop-margin;
		border-top: @global-main-nav-item-desktop-border-top;
		border-bottom: @global-main-nav-item-desktop-border-bottom;
	}

	.global-main-nav-link {
		height: @global-main-nav-link-desktop-height;
		line-height: @global-main-nav-link-desktop-line-height;
		padding: @global-main-nav-link-desktop-padding;

		font-size: @global-main-nav-link-desktop-font-size;
		text-transform: @global-main-nav-link-desktop-text-transform;
		font-weight: @global-main-nav-link-desktop-font-weight;
		color: @global-main-nav-link-desktop-color;

		// States _________
		.submenu-is-open > &,
		.global-nav-item:focus > &,
		.global-nav-item:hover > & {
			background-color: @global-nav-link-desktop-highlight-bg-color;
			color: @global-nav-link-desktop-highlight-color;
		}
	}



	// Child Lists
	// ---------------------------------------

	// the <li> that has a .global-nav-child-list
	.global-nav-item--has-children {
		position: relative;
	}

	.global-nav-child-list {
		// undo _mobile_ hiding
		max-width: 100%;

		// Hiding method
		max-height: 0;
		opacity: 0;
		overflow: hidden;

		position: @global-nav-child-list-desktop-position;
		left: @global-nav-child-list-desktop-left;
		right: @global-nav-child-list-desktop-right;
		top: @global-nav-child-list-desktop-top;
		z-index: @global-nav-child-list-desktop-z-index;

		min-width: @global-nav-child-list-desktop-min-width;
		padding: @global-nav-child-list-desktop-padding;
		margin: @global-nav-child-list-desktop-margin;
		border-top: @global-nav-child-list-desktop-border-top;
		border-bottom: @global-nav-child-list-desktop-border-bottom;

		background-color: @global-nav-child-list-desktop-bg-color;
		text-align: @global-nav-child-list-desktop-text-align;

		.transition (@global-nav-child-list-desktop-transition);
		.box-shadow (@global-nav-child-list-desktop-box-shadow);

		// Children _________
		.global-nav-item {
			// [*Note 1*] A semi-risky fix for Safari (macOS), which was for some reason triggering the opening of submenus for succeeding siblings when the mouse was in the upper submenu areas (or just below the parent list items & anchors). This is reset back to pointer-events: auto when the hover/focus/active action is applied to the parent. ... It's possible the bug showed up when I switched the default layout of this to use flexbox. A quick test of removing flexbox from the nav in Safari seemed to fix it. That'll be the fallback solution if this pointer-events solution doesn't work and we're not OK with the Safari hovering issue. --> KB 2017-08-24
			pointer-events: none;

			// child list link
			float: @global-nav-child-list-item-desktop-float;
			display: block;
			margin: 0;
			border-top: @global-nav-child-list-item-desktop-border-top;
			border-bottom: @global-nav-child-list-item-desktop-border-bottom;
		}
		.global-nav-link {
			display: block;

			// reset link height/padding so link text wider than the list's width will wrap
			height: auto;
			line-height: @global-nav-child-list-link-desktop-line-height;
			padding: @global-nav-child-list-link-desktop-padding;

			font-size: @global-nav-child-list-link-desktop-font-size;
			font-weight: @global-nav-child-list-link-desktop-font-weight;
			text-transform: @global-nav-child-list-link-desktop-text-transform;
			color: @global-nav-child-list-link-desktop-color;

			// not sure why this is here
			// clear: both;

			// States _________
			&:focus,
			&:hover {
				background-color: @global-nav-child-list-link-desktop-bg-highlight;
				color: @global-nav-child-list-link-desktop-highlight;
			}
		}

		// States _________

		// Override for hiding for specialy -mobile- only open menus
		.global-nav-item--has-children.submenu-is-mobile-open > & {
			max-height: 0;
			opacity: 0;
			overflow: hidden;
		}

		// Reveal the menu
		.global-nav-item--has-children.submenu-is-open > &,
		.global-nav-item--has-children:focus > &,
		.global-nav-item--has-children:hover > & {
			// Unhide
			max-height: 3000px;
			top: @global-nav-child-list-desktop-active-top;
			opacity: 1;
			overflow: visible;

			.transition (@global-nav-child-list-desktop-active-transition);

			.global-nav-item {
				// Reference [*Note 1*]
				pointer-events: auto;
			}
		}
	}



	// Child List Opening Behaviors
	// ---------------------------------------
	// .global-nav-item--opens-children {
	// 	// By default this doesn't change any styles
	// }

	.global-nav-item--has-child-nav-opener {
		// remove added space
		padding: @global-main-nav-item-desktop-padding;
	}
		.child-nav-opener {
			display: @child-nav-opener-desktop-display;
		}



	// Logo
	// ---------------------------------------
	// »»» Moved to _global-logo.less



	// open/close links
	// ---------------------------------------
	// ⏩⏩⏩ MOVED ⏩⏩⏩
	// Moved to:
	//  - _hamberger-menu-icon.less
	// .hamburger-menu-button {
	// 	display: none !important;
	// }
	// end moved code ⏩⏩⏩

	// »»» global search reveal button styles moved to its own file



	// Search
	// ---------------------------------------
	// »»» Styles moved to its own file



	// Main element
	// ---------------------------------------
	// ⏩⏩⏩ MOVED ⏩⏩⏩
	// Moved to:
	//  - _layout-main.less as it better fits with the existing file
	// Main element
	// --------------------------
	// @global-main-desktop-margin-top: 				@header-desktop-height;
	// .global-main {
	// 	padding-top: @global-main-desktop-margin-top;
	// }
	// end moved code ⏩⏩⏩
}
